import { Routes, Route } from "react-router-dom";

import PageAuth from "./pages/auth";
import PageFormProvidentFund from "./pages/provident_fund";
import PageFormConsent from "./pages/consent/index";
import PageFormConsentUR from "./pages/consent/643dec4affc480455db4c7f3";
import PageFormConsentWFH from "./pages/consent/643dec70ffc480455db4ca8f";
import PageFormConsentWSF from "./pages/consent/647e9f86ffc480455d3cfa81";

import PageFormConsentCFC1 from "./pages/consent/1_64ac7c00ffc480455da6da14";
import PageFormConsentCFC2 from "./pages/consent/2_64ad859fffc480455db08c12";
import PageFormConsentCFC3 from "./pages/consent/3_64ae4925ffc480455db7bb78";

import PageFormConsentCFCI2 from "./pages/consent/i2_64b68de4ffc480455d0a3386";
import PageFormConsentCFCI3 from "./pages/consent/i3_64b68debffc480455d0a33b5";

function App() {
  return (
    <Routes>

      <Route exact path="/" element={<PageAuth />} />
      <Route exact path="/provident_fund" element={<PageFormProvidentFund />} />
      <Route exact path="/consent" element={<PageFormConsent />} />

      <Route exact path="/consent/643dec4affc480455db4c7f3" element={<PageFormConsentUR />} />
      <Route exact path="/consent/643dec70ffc480455db4ca8f" element={<PageFormConsentWFH />} />
      <Route exact path="/consent/647e9f86ffc480455d3cfa81" element={<PageFormConsentWSF />} />

      <Route exact path="/consent/64ac7c00ffc480455da6da14" element={<PageFormConsentCFC1 />} />
      <Route exact path="/consent/64ad859fffc480455db08c12" element={<PageFormConsentCFC2 />} />
      <Route exact path="/consent/64ae4925ffc480455db7bb78" element={<PageFormConsentCFC3 />} />

      <Route exact path="/consent/64b68de4ffc480455d0a3386" element={<PageFormConsentCFCI2 />} />
      <Route exact path="/consent/64b68debffc480455d0a33b5" element={<PageFormConsentCFCI3 />} />
      
      <Route path="*" element={<div>Notfound</div>} />
      
    </Routes>
  );
}

export default App;
