import { useRef, useEffect, useState } from "react";
import {
  Row,
  Col,
  Card,
  Button,
  Form,
  Typography,
  Select,
  Input,
  Table,
  Space,
  Drawer,
  InputNumber,
  Steps,
  Checkbox,
  Layout,
  Spin,
  Avatar,
  Radio,
  Alert,
  Menu,
  Tour,
  Result,
  Dropdown,
  Descriptions,
  Modal,
  message
} from "antd";
import moment from 'moment'
import { CheckOutlined, GoogleOutlined, ExclamationCircleOutlined } from "@ant-design/icons";
import { useParams, useNavigate , Link } from 'react-router-dom'
import { company, prefix } from "./index.const.jsx";

const { confirm } = Modal;
const { Step } = Steps;
const { Title, Text, Paragraph } = Typography;
const { Header, Content, Footer  } = Layout;

const Page = () => {

  const navigate = useNavigate();
  const id = '647e9f86ffc480455d3cfa81';

  const [form_read] = Form.useForm();
  const [form_sign] = Form.useForm();
  const [form_confirm] = Form.useForm();

  const [loading, setLoading] = useState(false);
  const [stepForm, setStepForm] = useState(1);


  const [mounted, setMounted] = useState(false);
  const [authData, setAuthData] = useState(false);
  const [checked, setChecked] = useState(false);
  const [companyName, setCompanyName] = useState('');
  const [formDataSign, setFormDataSign] = useState({});

  const [formData, setFormData] = useState({name:'',drive:[]});



  const company_index = {
        "BA":"BA",
        "BC":"BCGH",
        "BO":"BO",
        "BM":"BM",
        "BV":"BV",
        "BI":"BI",
        "BW":"BWT",
        "BP":"BP",
        "BB":"BBT",
    }


  const fetchForm = (callback) => {
      fetch('/api/consent/'+id)
        .then(async response => {
          const data = await response.json();
          console.log('data=====>',data)
          if(!response.ok) {
            const error = (data && data.message) || response.statusText;
            return Promise.reject(error);
          }
          if(data.status == false) {
             setStepForm(4)
             callback()
          } else {
            setFormData(data.data)
            callback()
          }
        }).catch(error => {
          setStepForm(4)
          callback()
        })
  }

  useEffect(() => {
      setMounted(false)
      fetchForm(()=>{
        fetch('/process/auth/check')
          .then(async response => {
            const data = await response.json();
            console.log('data=====>',data)
            if(!response.ok) {
              const error = (data && data.message) || response.statusText;
              return Promise.reject(error);
            }
            if(data.status == false) {
               navigate("/?form=/consent/"+id);
            } else {
              var _company = company.filter(item=>item.key==company_index[data.user.emp_id.slice(0,2)])[0]
              console.log('_company===>',_company)
              
              setCompanyName(_company.label_en)


              setAuthData(data)
              setFormDataSign({
                sign_date:moment().format('YYYY-MM-DD'),
                company_name:_company.label_en,
                company_key:_company.key,
                company:{},
                name:'',
                team:'',
                position:'',
                email:data.user.email,
                employee_id:data.user.emp_id,
                employee_type:'Full Time (work shift)',
                working_date:'',
                working_hours:'',
                effective_date:'',
              })

              setMounted(true)
            }
          }).catch(error => {
            console.log('error=>call api',error)
          })
      })
   
  },[])



  const onChange = (e) => {
    console.log(`checked = ${e.target.checked}`);
    setChecked(e.target.checked)
  };

  const onFinishSign = (values) => {
    console.log(values)
    if(checked==true){
      values.company = formData.drive.filter(item=>item.key==formDataSign.company_key)[0]
      console.log('_company_data===>',values,formDataSign.company_key)
      setFormDataSign({...formDataSign,...values})
      console.log({...formDataSign,...values})

      message.success('Create Electronic signature complete')
      setStepForm(2)

    }
  }


  const RenderFormSign = () => {
    return (
    <>
      <Form form={form_sign} layout="vertical" onFinish={onFinishSign} initialValues={formDataSign}>
        <Row gutter={[24, 24]} className="mb-10">
          <Col span={24}>
              <Card className="form-law">
                  <Row gutter={[24, 32]}>
                    <Col span={24} align="right">
                      <Title level={4} underline align="center">
                        WORK SCHEDULE FORM
                      </Title>
                      <Text>
                        Date: {formDataSign.sign_date}
                      </Text>
                    </Col>

                    <Col span={24}>
                      <Space direction="vertical" style={{ display: "flex" }}>
                        <Card title="DETAILS OF EMPLOYEE">
                          <Row gutter={[12, 12]}>
                            <Col xs={{ span: 24 }} lg={{ span: 24 }}>
                              <Form.Item name="name" label="Name of employee (Employee):"  rules={[{required: true,message: ""}]}>
                                <Input className="w-100" placeholder="Full Name (EN)"/>
                              </Form.Item>
                            </Col>
                            <Col xs={{ span: 24 }} lg={{ span: 12 }}>
                              <Form.Item name="company_name"  label="Name of employer (Company):" rules={[{required: true,message: ""}]}>
                                
                                <Input className="w-100"  placeholder="Company name Co.,Ltd."/>
                              </Form.Item>
                            </Col>
                            <Col xs={{ span: 24 }} lg={{ span: 12 }}>
                              <Form.Item name="team"  label="Team:" rules={[{required: true,message: ""}]}>
                                <Input className="w-100" placeholder="Team"/>
                              </Form.Item>
                            </Col>
                            <Col xs={{ span: 24 }} lg={{ span: 12 }}>
                              <Form.Item name="position" label="Position:" rules={[{required: true,message: ""}]}>
                                <Input className="w-100"  placeholder="Position"/>
                              </Form.Item>
                            </Col>
                            <Col xs={{ span: 24 }} lg={{ span: 12 }}>
                              <Form.Item name="employee_id" label="Employee ID:(Ex.BCGH001)" rules={[{required: true,message: ""}]}>
                                <Input className="w-100" />
                              </Form.Item>
                            </Col>
                            <Col xs={{ span: 24 }} lg={{ span: 12 }}>
                              <Form.Item name="employee_type" label="Employee Type:(Ex. Full Time (work shift))" placeholder="Full Time (work shift)" rules={[{required: true,message: ""}]}>
                                <Input className="w-100"  placeholder="Full Time (work shift)"/>
                              </Form.Item>
                            </Col>
                            <Col xs={{ span: 24 }} lg={{ span: 12 }}>
                              <Form.Item name="email" label="Email:" rules={[{required: true,message: ""}]}>
                                <Input className="w-100" />
                              </Form.Item>
                            </Col>
                          </Row>
                        </Card>
                        <Card title="DETAILS OF WORK SCHEDULE">

                         <Row gutter={[12, 12]}>
                            <Col xs={{ span: 24 }} lg={{ span: 12 }}>
                              <Form.Item name="working_date" label="Working days:(Ex. Mon-Fri)" rules={[{required: true,message: ""}]}>
                                <Input className="w-100" placeholder="Mon-Fri"  />
                              </Form.Item>
                            </Col>
                            <Col xs={{ span: 24 }} lg={{ span: 12 }}>
                              <Form.Item name="working_hours" label="Working hours:(Ex. 10.00-19.00)" rules={[{required: true,message: ""}]}>
                                <Input className="w-100" placeholder="00.00-00.00"/>
                              </Form.Item>
                            </Col>
                            <Col xs={{ span: 24 }} lg={{ span: 24 }}>
                              <Form.Item name="effective_date" label="Effective date:" rules={[{required: true,message: ""}]}>
                                <Input className="w-100" placeholder="YYYY-MM-DD"/>
                              </Form.Item>
                            </Col>
                          </Row>

                        </Card>
                        <Title level={5} underline>
                          Terms and conditions
                        </Title>
                        <ol>
                          <li>
                            The Employee agrees that the Company shall have a right to
                            reschedule the Employee’s work schedule (working days and/or
                            working hours) as the Company sees appropriate according to the
                            Company’s work rules and applicable laws.
                          </li>
                          <li>
                            The Employee shall have a right to request for any changes of the
                            work schedule for the Company’s consideration and approval.
                          </li>
                          <li>
                            The Employee agrees that this Work Schedule Form{" "}
                            <Text strong>(“Form”)</Text> shall be a part of the employment
                            agreement executed between the Company and Employee.
                          </li>
                        </ol>
                        <Paragraph>
                          The Employee has thoroughly read and agreed with the above work
                          schedule as well as terms and conditions as set out in this Form.
                          <br />
                          <Text strong>IN WITNESS WHEREOF,</Text> the Employee has duly
                          executed this Form as of the day and year first above written.
                        </Paragraph>
                      </Space>
                    </Col>

                    <Col span={24} align="right">
                      <Space align="top">
                        <Text>Signed:</Text>
                        <Space direction="vertical">
                          <Text>______________________________</Text>
                          <Text>
                            (......................................................)
                          </Text>
                        </Space>
                      </Space>
                    </Col>
                  </Row>
              </Card>
          </Col>
          <Col span={24} align="left">
              
                <Checkbox onChange={onChange}>ข้าพเจ้าได้อ่าน และเข้าใจรายละเอียด รวมถึงได้ทำการกรอกข้อมูลตามความเป็นจริง ข้างต้นทั้งหมดครบถ้วนดีแล้ว</Checkbox>
           
          </Col>
          <Col span={24} align="left">
      
              <Button type="primary" htmlType="submit" disabled={!checked}>
                ยืนยันการลงลายมือชื่อผ่านระบบอิเล็กทรอนิกส์
              </Button>
     
          </Col>
        </Row>
      </Form>

    </>
    )
  }



  const RenderFormConfirm = () => {
    return (
    <>
      <Form form={form_confirm} layout="vertical" onFinish={onFinishConfirm}>
        <Row gutter={[24, 24]} className="mb-10">
          <Col span={24}>
              <Card className="form-law">
                  <Row gutter={[24, 32]}>
                    <Col span={24} align="right">
                      <Title level={4} underline align="center">
                        WORK SCHEDULE FORM
                      </Title>
                      <Text>
                        Date: {formDataSign.sign_date}
                      </Text>
                    </Col>

                    <Col span={24}>
                      <Space direction="vertical" style={{ display: "flex" }}>
                        <Card title="DETAILS OF EMPLOYEE">
                          <Row gutter={[12, 12]}>
                            <Col xs={{ span: 24 }} lg={{ span: 24 }}>
                              <Form.Item name="name" label="Name of employee (Employee):" rules={[{required: true,message: ""}]}>
                                <Text strong className="ant-form-text">{formDataSign.name}</Text>
                              </Form.Item>
                            </Col>
                            <Col xs={{ span: 24 }} lg={{ span: 12 }}>
                              <Form.Item name="company_name"  label="Name of employer (Company):" rules={[{required: true,message: ""}]}>
                                
                                <Text strong className="ant-form-text">{formDataSign.company_name}</Text>
                              </Form.Item>
                            </Col>
                            <Col xs={{ span: 24 }} lg={{ span: 12 }}>
                              <Form.Item name="team"  label="Team:" rules={[{required: true,message: ""}]}>
                                <Text strong className="ant-form-text">{formDataSign.team}</Text>
                              </Form.Item>
                            </Col>
                            <Col xs={{ span: 24 }} lg={{ span: 12 }}>
                              <Form.Item name="position" label="Position:" rules={[{required: true,message: ""}]}>
                                <Text strong className="ant-form-text">{formDataSign.position}</Text>
                              </Form.Item>
                            </Col>
                            <Col xs={{ span: 24 }} lg={{ span: 12 }}>
                              <Form.Item name="employee_id" label="Employee ID:(Ex.BCGH001)" rules={[{required: true,message: ""}]}>
                                <Text strong className="ant-form-text">{formDataSign.employee_id}</Text>
                              </Form.Item>
                            </Col>
                            <Col xs={{ span: 24 }} lg={{ span: 12 }}>
                              <Form.Item name="employee_type" label="Employee Type:(Ex. Full Time (Work Shift))" rules={[{required: true,message: ""}]}>
                                <Text strong className="ant-form-text">{formDataSign.employee_type}</Text>
                              </Form.Item>
                            </Col>
                            <Col xs={{ span: 24 }} lg={{ span: 12 }}>
                              <Form.Item name="email" label="Email:" rules={[{required: true,message: ""}]}>
                                <Text strong className="ant-form-text">{formDataSign.email}</Text>
                              </Form.Item>
                            </Col>
                          </Row>
                        </Card>
                        <Card title="DETAILS OF WORK SCHEDULE">

                         <Row gutter={[12, 12]}>
                            <Col xs={{ span: 24 }} lg={{ span: 12 }}>
                              <Form.Item name="working_date" label="Working days:(Ex. Mon-Fri)" rules={[{required: true,message: ""}]}>
                                <Text strong className="ant-form-text">{formDataSign.working_date}</Text>
                              </Form.Item>
                            </Col>
                            <Col xs={{ span: 24 }} lg={{ span: 12 }}>
                              <Form.Item name="working_hours" label="Working hours:(Ex. 10.00-19.00)" rules={[{required: true,message: ""}]}>
                                <Text strong className="ant-form-text">{formDataSign.working_hours}</Text>
                              </Form.Item>
                            </Col>
                            <Col xs={{ span: 24 }} lg={{ span: 24 }}>
                              <Form.Item name="effective_date" label="Effective date:">
                                <Text strong className="ant-form-text">{formDataSign.effective_date}</Text>
                              </Form.Item>
                            </Col>
                          </Row>

                        </Card>
                        <Title level={5} underline>
                          Terms and conditions
                        </Title>
                        <ol>
                          <li>
                            The Employee agrees that the Company shall have a right to
                            reschedule the Employee’s work schedule (working days and/or
                            working hours) as the Company sees appropriate according to the
                            Company’s work rules and applicable laws.
                          </li>
                          <li>
                            The Employee shall have a right to request for any changes of the
                            work schedule for the Company’s consideration and approval.
                          </li>
                          <li>
                            The Employee agrees that this Work Schedule Form{" "}
                            <Text strong>(“Form”)</Text> shall be a part of the employment
                            agreement executed between the Company and Employee.
                          </li>
                        </ol>
                        <Paragraph>
                          The Employee has thoroughly read and agreed with the above work
                          schedule as well as terms and conditions as set out in this Form.
                          <br />
                          <Text strong>IN WITNESS WHEREOF,</Text> the Employee has duly
                          executed this Form as of the day and year first above written.
                        </Paragraph>
                      </Space>
                    </Col>

                    <Col span={24} align="right">
                      <Space align="top">
                        <Text>Signed:</Text>
                        <Space direction="vertical"  align="center">
                          <Text strong>_________{formDataSign.name}________</Text>
                          <Text>(..........{formDataSign.name}..............)</Text>


                        </Space>
                      </Space>
                    </Col>
                  </Row>
              </Card>
          </Col>

          <Col span={24} align="left">
      
              <Button type="primary" onClick={onFinishConfirm}>
                ยืนยันการลงลายมือชื่อผ่านระบบอิเล็กทรอนิกส์
              </Button>
     
          </Col>
        </Row>
      </Form>

    </>
    )
  }

  function onFinishConfirm() {

    confirm({
      icon: <ExclamationCircleOutlined />,
      title:"Confirm ?",
      content: 'ยืนยันการลงลายมือชื่อผ่านระบบอิเล็กทรอนิกส์?',
      onOk() {
        setLoading(true)
        var body = {
         method: 'post',
         headers: {'Content-Type':'application/json'},
         body: JSON.stringify(formDataSign)
        }
        console.log(body)
        fetch('/api/consent/'+id,body)
          .then(async response => {
            const data = await response.json();
            console.log('data=====>',data)
            if(!response.ok) {
              const error = (data && data.message) || response.statusText;
              return Promise.reject(error);
            }else{
              message.success('Complete')
              setStepForm(3)
              setLoading(false)
            }
          }).catch(error => {
            message.error('กรุณาลองใหม่อีกครั้ง')
            setLoading(false)
            console.log('error=>call api',error)
          })
      },
      onCancel() {
        console.log('Cancel');
      },
    });

  }

  function onBack() {
      setStepForm(2)
  }


  const RenderFormResult = () => {
    return (
       <Result
          status="success"
          title="ทำการส่งแบบฟอร์มสำเร็จ"
          extra={[
            <a href='/process/auth/logout/'><Button type="primary">
              Done and Logout
            </Button></a>
          ]}
        />
      )
  }


  const RenderFormResultEmpty = () => {
    return (
       <Result
          title="ไม่พบแบบแฟอร์ม"
          extra={[
            <a href='/process/auth/logout/'><Button type="primary">
              Done and Logout
            </Button></a>
          ]}
        />
      )
  }



  return mounted ? (
    <Spin spinning={loading} delay={500}>
      <Layout>
          <Header className="w-100" theme={'light'}>
            <Space className="w-100 justify-space-between">

                <Space size="middle"  align="center">
                  <a className="App-brand"><img src={'/logo.png'} style={{ height: 39 }}/></a>
                </Space>
                <Space size="middle"  align="center">
                      <Dropdown overlay={(
                <Menu>
                    <Menu.Item key="1">
                        <a href='/process/auth/logout/'>Logout</a>
                    </Menu.Item>
                </Menu>
            )} trigger={["click"]}>
                          <a className="ant-dropdown-link" onClick={(e) => e.preventDefault()}>
                              <Space>
                                  <Avatar src={authData.user.avatar} className="mr-2" />
                                  <div className="mr-2">
                                      <h5 className="mb-0">
                                          {authData.user.name}
                                      </h5>
                                  </div>
                              </Space>
                          </a>
                      </Dropdown>
                </Space>
            </Space>
          </Header>

          <Layout className="wrap">
              {(mounted && stepForm ==1) && <RenderFormSign />}
              {(mounted && stepForm ==2) && <RenderFormConfirm />}
              {(mounted && stepForm ==3) && <RenderFormResult />}
              {(mounted && stepForm ==4) && <RenderFormResultEmpty />}
          </Layout>
      </Layout>
    </Spin>
  ) : (<></>);
};

export default Page;
