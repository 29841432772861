import { Typography} from "antd";
const { Title, Text } = Typography;


export const company = [
  {key:'BCGH',value:"บิทคับ แคปปิตอล กรุ๊ป โฮลดิ้งส์",label:"BCGH : บริษัท บิทคับ แคปปิตอล กรุ๊ป โฮลดิ้งส์ จำกัด",label_en:"BITKUB CAPITAL GROUP HOLDINGS CO., LTD."},
  {key:'BO',value:"บิทคับ ออนไลน์",label:"BO : บริษัท บิทคับ ออนไลน์ จำกัด",label_en:"BITKUB ONLINE CO., LTD."},
  {key:'BBT',value:"บิทคับ บล็อคเชน เทคโนโลยี",label:"BBT : บริษัท บิทคับ บล็อคเชน เทคโนโลยี จำกัด",label_en:"BITKUB BLOCKCHAIN TECHNOLOGY CO., LTD."},
  {key:'BA',value:"บิทคับ แล็บส์",label:"BA : บริษัท บิทคับ แล็บส์ จำกัด",label_en:"BITKUB LABS CO., LTD."},
  {key:'BV',value:"บิทคับ เวนเจอร์ส",label:"BV : บริษัท บิทคับ เวนเจอร์ส จำกัด",label_en:"BITKUB VENTURES CO., LTD."},
  {key:'BI',value:"บิทคับ อินฟินิตี",label:"BI : บริษัท บิทคับ อินฟินิตี จำกัด",label_en:"BITKUB INFINITY CO., LTD."},
  {key:'BM',value:"บิทคับ เอ็ม",label:"BM : บริษัท บิทคับ เอ็ม จำกัด",label_en:"BITKUB M CO., LTD."},
  {key:'BW',value:"บิทคับ เวิลด์เทค",label:"BW : บริษัท บิทคับ เวิลด์เทค จำกัด",label_en:"BITKUB WORLDTECH CO., LTD."},
  {key:'BP',value:"บิทคับ พอร์ทอล",label:"BP : บริษัท บิทคับ พอร์ทอล จำกัด",label_en:"BITKUB PORTAL CO., LTD."},
  {key:'BAV',value:"บิทคับ แอดไวเซอรี่",label:"BAV : บริษัท บิทคับ แอดไวเซอรี่ จำกัด",label_en:"BITKUB ADVISORY CO., LTD."},
  {key:'BC',value:"บิทคับ คัสโตดี",label:"BC : บริษัท บิทคับ คัสโตดี จำกัด",label_en:"BITKUB CUSTODY CO., LTD."},
]

export const prefix = [
  {
    value: "นาย",
    label: "นาย",
  },
  {
    value: "นาง",
    label: "นาง",
  },
  {
    value: "นางสาว",
    label: "นางสาว",
  },
]

export const percent = [
  {
    value: "5",
    label: "5%",
  },
  {
    value: "10",
    label: "10%",
  },

  {
    value: "15",
    label: "15%",
  },
]


export const plan = [

    {
      key:1,
      choice: "F100",
      ratio: (
        <Text>
          (ความเสี่ยงค่อนข้างต่ำ) <br />
          เงินทั้งหมด 100% ของสมาชิกจะอยู่ในนโยบาย
          <Text strong underline>
            ตราสารหนี้
          </Text>
        </Text>
      ),
    },
    {
      key:2,
      choice: "F80 : E20",
      ratio: (
        <Typography>
          (ความเสี่ยงค่อนข้างต่ำ) <br />
          เงินสะสม-เงินสมทบที่นำส่งกองทุนในแต่ละเดือน
          จะนำส่งเข้ากองทุนภายใต้นโยบาย
          <Text strong underline>
            ตราสารหนี้ 80%
          </Text>{" "}
          และนำส่งเข้ากองทุนภายใต้นโยบาย
          <Text strong underline>
            ตราสารทุน 20%
          </Text>
        </Typography>
      ),
    },
    {
      key:4,
      choice: "F80 : E15 : FIF5",
      ratio: (
        <Typography>
          (ความเสี่ยงค่อนข้างต่ำ)<br />
          เงินสะสม-เงินสมทบที่นำส่งกองทุนในแต่ละเดือน
          จะนำส่งเข้ากองทุนภายใต้นโยบาย
          <Text strong underline>
            ตราสารหนี้ 80%
          </Text>{" "}
          และนำส่งเข้ากองทุนภายใต้นโยบาย
          <Text strong underline>
            ตราสารทุน 15%
          </Text>{" "}
          และนำส่งเข้ากองทุนภายใต้นโยบาย
          <Text strong underline>
            หน่วยลงทุนกองทุนรวมต่างประเทศ 5%
          </Text>
        </Typography>
      ),
    },
    {
      key:5,
      choice: "F70 : E20 : FIF10",
      ratio: (
        <Typography>
         (ความเสี่ยงปานกลาง)<br />
          เงินสะสม-เงินสมทบที่นำส่งกองทุนในแต่ละเดือน
          จะนำส่งเข้ากองทุนภายใต้นโยบาย
          <Text strong underline>
            ตราสารหนี้ 70%
          </Text>{" "}
          และนำส่งเข้ากองทุนภายใต้นโยบาย
          <Text strong underline>
            ตราสารทุน 20%
          </Text>{" "}
          และนำส่งเข้ากองทุนภายใต้นโยบาย
          <Text strong underline>
            หน่วยลงทุนกองทุนรวมต่างประเทศ 10%
          </Text>
        </Typography>
      ),
    },
    {
      key:3,
      choice: "MR60 : E40",
      ratio: (
        <Typography>
          (ความเสี่ยงสูง)<br />
          เงินสะสม-เงินสมทบที่นำส่งกองทุนในแต่ละเดือน
          จะนำส่งเข้ากองทุนภายใต้นโยบาย
          <Text strong underline>
            หน่วยลงทุน-ผสม 60%
          </Text>{" "}
          และนำส่งเข้ากองทุนภายใต้นโยบาย
          <Text strong underline>
            ตราสารทุน 40%
          </Text>
        </Typography>
      ),
    },



    // {
    //   key:1,
    //   choice: "F100",
    //   ratio: (
    //     <Text>
    //       เงินทั้งหมด 100% ของสมาชิกจะอยู่ในนโยบาย
    //       <Text strong underline>
    //         ตราสารหนี้
    //       </Text>
    //     </Text>
    //   ),
    // },
    // {
    //   key:2,
    //   choice: "F80 : E20",
    //   ratio: (
    //     <Typography>
    //       เงินสะสม-เงินสมทบที่นำส่งกองทุนในแต่ละเดือน
    //       จะนำส่งเข้ากองทุนภายใต้นโยบาย
    //       <Text strong underline>
    //         ตราสารหนี้ 80%
    //       </Text>{" "}
    //       และนำส่งเข้ากองทุนภายใต้นโยบาย
    //       <Text strong underline>
    //         ตราสารทุน 20%
    //       </Text>
    //     </Typography>
    //   ),
    // },
    // {
    //   key:3,
    //   choice: "MR60 : E40",
    //   ratio: (
    //     <Typography>
    //       เงินสะสม-เงินสมทบที่นำส่งกองทุนในแต่ละเดือน
    //       จะนำส่งเข้ากองทุนภายใต้นโยบาย
    //       <Text strong underline>
    //         หน่วยลงทุน-ผสม 60%
    //       </Text>{" "}
    //       และนำส่งเข้ากองทุนภายใต้นโยบาย
    //       <Text strong underline>
    //         ตราสารทุน 40%
    //       </Text>
    //     </Typography>
    //   ),
    // },
    // {
    //   key:4,
    //   choice: "F80 : E15 : FIF5",
    //   ratio: (
    //     <Typography>
    //       เงินสะสม-เงินสมทบที่นำส่งกองทุนในแต่ละเดือน
    //       จะนำส่งเข้ากองทุนภายใต้นโยบาย
    //       <Text strong underline>
    //         ตราสารหนี้ 80%
    //       </Text>{" "}
    //       และนำส่งเข้ากองทุนภายใต้นโยบาย
    //       <Text strong underline>
    //         ตราสารทุน 15%
    //       </Text>{" "}
    //       และนำส่งเข้ากองทุนภายใต้นโยบาย
    //       <Text strong underline>
    //         หน่วยลงทุนกองทุนรวมต่างประเทศ 5%
    //       </Text>
    //     </Typography>
    //   ),
    // },
    // {
    //   key:5,
    //   choice: "F70 : E20 : FIF10",
    //   ratio: (
    //     <Typography>
    //       เงินสะสม-เงินสมทบที่นำส่งกองทุนในแต่ละเดือน
    //       จะนำส่งเข้ากองทุนภายใต้นโยบาย
    //       <Text strong underline>
    //         ตราสารหนี้ 70%
    //       </Text>{" "}
    //       และนำส่งเข้ากองทุนภายใต้นโยบาย
    //       <Text strong underline>
    //         ตราสารทุน 20%
    //       </Text>{" "}
    //       และนำส่งเข้ากองทุนภายใต้นโยบาย
    //       <Text strong underline>
    //         หน่วยลงทุนกองทุนรวมต่างประเทศ 10%
    //       </Text>
    //     </Typography>
    //   ),
    // },
  ];

export const propsCodeInput = {
    inputStyle: {
      margin: "4px",
      MozAppearance: "textfield",
      width: "30px",
      borderRadius: "2px",
      fontSize: "12px",
      height: "30px",
      textAlign: "center",
      backgroundColor: "white",
      color: "black",
      border: "1px solid #d9d9d9",
    },
    inputStyleInvalid: {
      margin: "4px",
      MozAppearance: "textfield",
      width: "30px",
      borderRadius: "2px",
      fontSize: "12px",
      height: "30px",
      textAlign: "center",
      backgroundColor: "white",
      color: "black",
      border: "1px solid red",
    },
  };

export const team = {
"Project Management":["Project Management"],
"Accounting":["Accounting"],
"Academy Business":["Academy Business","Content","Operations","Business Development"],
"Advisory":["Advisory","Digital Asset Analyst"],
"Business Development":["Business Development","Business Expansion"],
"CEO Office":["CEO Office"],
"Compliance":["Compliance"],
"Corporate Secretary":["Corporate Secretary"],
"Corporate Strategy":["Corporate Strategy"],
"Strategy":["Strategy"],
"Cryptonomics":["Cryptonomics"],
"Customer Service":["Customer Service"],
"Finance & Accounting":["Accounting","Finance","Custody","Procurement","Strategic Finance"],
"Fund Management":["Fund Management"],
"Fund Operation":["Fund Operation"],
"Government Affairs":["Government Affairs"],
"Graphic Design":["Graphic Design"],
"Group PR & Communication":["Public Affairs","Media & Production","Community & Campaign Growth","Corporate Branding"],
"IT":["IT"],
"Inventory Control":["Inventory Control"],
"Investment":["Investment"],
"IT Security":["Identity & Access Mgmt","IT Security Advisory"],
"Legal":["Legal"],
"Management":["Management"],
"Marketing":["Graphic Designer","Marketing","Community Mgmt","Event Coordinator","Copywriter"],
"Marketing & Event":["Marketing & Event"],
"BO-Ops & CS":["Customer Experience","Customer Protection","Operations","Operations (QA)","Operations Excellence","Operations KM"],
"Ops & CS":["Ops & CS"],
"People":["People"],
"Procurement":["Procurement"],
"Product":["Product Management","Product Design","Product","Product Operations","Experience Design"],
"Relationship Mgmt":["Relationship Mgmt"],
"Risk Managememt":["Risk Managememt","Market Surveillance"],
"Sales & Marketing":["Marketing"],
"Secretary":["Secretary"],
"Social MKTG & Brand Commu":["Social MKTG & Brand Commu"],
"Space Management":["Space Management"],
"Technology":["Blockchain","Backoffice Developer","IT Product","Frontend Developer","IT Security & Infrastructure","Quality Assurance","Backend Developer","Mobile Application","Technology","Architect","Data","Development","Engineering","Platform","Project","Project Management","TechOps"],
"Data Protection":["Data Protection"],
"Digital Asset Strategist & Event":["Digital Asset Strategist"],
"Internal Audit":["Internal Audit"],
// "Accounting":["Accounting"],
// "Academy Business":["Academy Business","Content","Operations","Business Development"],
// "Advisory":["Advisory","Digital Asset Analyst"],
// "Business Development":["Business Development","Business Expansion"],
// "CEO Office":["CEO Office"], 
// "Compliance":["Compliance"],
// "Corporate Secretary":["Corporate Secretary"],
// "Corporate Strategy":["Corporate Strategy"],
// "Strategy":["Strategy"],
// "Cryptonomics":["Cryptonomics"],
// "Customer Service":["Customer Service"],
// "Finance & Accounting":["Accounting","Finance","Custody","Procurement","Strategic Finance"],
// "Fund Management":["Fund Management"],
// "Fund Operation":["Fund Operation"],
// "Government Affairs":["Government Affairs"],
// "Graphic Design":["Graphic Design"],
// "Group PR & Communication":["Public Affairs","Media & Production","Community & Campaign Growth Support","Corporate Branding"],
// "Information Technology":["Information Technology"],
// "Inventory Control":["Inventory Control"],
// "Investment":["Investment"],
// "IT Security":["Identity & Access Management","IT Security Advisory","Corporate IT"],
// "Legal":["Legal"],
// "Management":["Management"],
// "Marketing":["Graphic Designer","Marketing","Community Management","Event Coordinator","Copywriter"],
// "Marketing & Event":["Marketing & Event"],
// "Operations & Customer Support":["Operation & Customer Support"],
// "BO-Operations & Customer Support":['Customer Experience','Customer Protection','Operations','Operations (QA)','Operations Excellence','Operations Knowledge Management'],
// "People":["People"],
// "Procurement":["Procurement"],
// "Product":["Product Management","Product Design","Product","Product Operations","Experience Design"],
// "Relationship Management":["Relationship Management"],
// "Risk Managememt":["Risk Managememt","Market Surveillance"],
// "Sales & Marketing":["Marketing"],
// "Secretary":["Secretary"],
// "Social Marketing & Brand Communications":["Social Marketing & Brand Communications"],
// "Space Management":["Space Management"],
// "Technology":["Blockchain","Backoffice Developer","IT Product","Frontend Developer","IT Security & Infrastructure","Quality Assurance","Backend Developer","Mobile Application","Technology","Architect","Data","Development","Engineering","Platform","Project","Project Management","TechOps"],
// "Data Protection":["Data Protection"],
// "Digital Asset Strategist & Event":["Digital Asset Strategist"],
// "Internal Audit":["Internal Audit"]
}
