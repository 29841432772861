import { useEffect, useState } from "react";
import {
  Row,
  Col,
  Card,
  Button,
  Form,
  Typography,
  Select,
  Input,
  Table,
  Space,
  Drawer,
  InputNumber,
  Checkbox,
  Layout
} from "antd";
import { CheckOutlined, GoogleOutlined } from "@ant-design/icons";
import { useSearchParams } from 'react-router-dom';

const { Title, Text } = Typography;

const Page = () => {
  const [searchParams] = useSearchParams();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
      var found = searchParams.get('form')
      if(found){
        setLoading(true)
        var body = {
         method: 'post',
         headers: {'Content-Type':'application/json'},
         body: JSON.stringify({url:searchParams.get('form')})
        }
        fetch('/process/auth/cache',body)
          .then(async response => {
              setLoading(false)
          }).catch(error => {
            setLoading(false)
          })
      }
  },[])



  return (
    <>

          <div style={{margin: 0,display: 'flex',alignItems: 'center',justifyContent: 'center'}}>
            <Card style={{maxWidth: '450px',marginTop: '100px',width: '90%'}} className="Wrap">
                <img src="/logo.png" style={{'height': 39,marginLeft:-10}}/>
                <div style={{paddingTop: '20px',marginBottom: '10px'}}>
                    <Button type="primary" block size="large" icon={<GoogleOutlined />} onClick={()=>{
                       window.location.pathname = '/process/auth/redirect';
                    }}> Login </Button>
                </div>
                <small>BITKUB CAPITAL GROUP HOLDINGS CO., LTD.</small>
            </Card>
          </div>

    </>
  );
};

export default Page;
