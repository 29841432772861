import { useRef, useEffect, useState } from "react";
import {
  Row,
  Col,
  Card,
  Button,
  Form,
  Typography,
  Select,
  Input,
  Table,
  Space,
  Drawer,
  InputNumber,
  Steps,
  Checkbox,
  Layout,
  Spin,
  Avatar,
  Radio,
  Alert,
  Menu,
  Tour,
  Result,
  Dropdown,
  message
} from "antd";
import { CheckOutlined, GoogleOutlined } from "@ant-design/icons";
import ReactCodeInput from "react-code-input";
import { useNavigate , Link } from 'react-router-dom'

import { propsCodeInput, company, prefix, percent, plan, team } from "./index.const.jsx";

const { Step } = Steps;
const { Title, Text } = Typography;
const { Header, Content, Footer  } = Layout;
const Page = () => {

  const navigate = useNavigate();

  const [form_company] = Form.useForm();
  const [form_benefit] = Form.useForm();
  const [form_benefit_drawer] = Form.useForm();
  const [form_benefit_edit_drawer] = Form.useForm();
  const [form_invest] = Form.useForm();
  const [isCardIdValid, setIsCardIdValid] = useState(true);
  const [isMemberIdValid, setIsMemberIdValid] = useState(true);

  const [beneficiary, setBeneficiary] = useState([]);
  const [open, setOpen] = useState(false);
  const [openEdit, setOpenEdit] = useState(false);
  const [total, setTotal] = useState(0);

  const [formDataCompany, setFormDataCompany] = useState({});
  const [formDataInvest, setFormDataInvest] = useState({});
  const [formDataBenefit, setFormDataBenefit] = useState({});

  const [formDataBenefitEdit, setFormDataBenefitEdit] = useState({});
  const [formDataBenefitIndex, setFormDataBenefitIndex] = useState(0);

  const [loading, setLoading] = useState(false);
  const [stepForm, setStepForm] = useState(1);


  const [mounted, setMounted] = useState(false);
  const [authData, setAuthData] = useState(false);

  useEffect(() => {
      setMounted(false)
      fetch('/process/auth/check/')
        .then(async response => {
          const data = await response.json();
          console.log('data=====>',data)
          if(!response.ok) {
            const error = (data && data.message) || response.statusText;
            return Promise.reject(error);
          }
          if(data.status == false) {
             navigate("/");
          } else {
            setAuthData(data)
            setMounted(true)
          }
        }).catch(error => {
          console.log('error=>call api',error)
        })
   
  },[])


  const onFinishCompany = (values) => {
    setIsCardIdValid(values.card_id.length === 13);
    if (values.card_id.length === 13)
    message.success('complete step 1')

    setFormDataCompany({...values,company:company.filter(item=>item.value==values.company_name)[0]})
    setStepForm(2)
    setTimeout(() => {
      setInvestOpen(true)
    }, 1000)

  }


  const employee_team = Object.keys(team);//provinceData
  const employee_department = team //cityData

  const [department, setDepartment] = useState(employee_department[employee_team[0]]);
  const [secondDepartment, setSecondDepartment] = useState(employee_department[employee_team[0]][0]);

  const handleTeamChange = (value) => {
    setDepartment(employee_department[value]);
    // setSecondDepartment(employee_department[value][0]);
    form_company.setFieldsValue({employee_department:employee_department[value][0]});
  };

  const onSecondDepartmentChange = (value) => {
    setSecondDepartment(value);
  };


  const RenderFormCompany = () => {
    return (
      <Form form={form_company} layout="vertical" onFinish={onFinishCompany}>
        <Row gutter={[24, 24]} className="mb-10">
          <Col span={24}>
            <Card>
              <Row gutter={[12, 0]}>
                <Col span={24} align="center">
                  <Title level={5}>
                  ข้อมูลพนักงาน
                  </Title>
                </Col>
                <Col xs={{ span: 24 }} lg={{ span: 8 }}>
                      <Form.Item label="บริษัท" name="company_name" rules={[{required: true,message: ""}]}>
                        <Select placeholder="โปรดเลือก" className="w-100" options={company} />
                      </Form.Item>
                </Col>
                <Col xs={{ span: 24 }} lg={{ span: 4 }}>
                      <Form.Item label="รหัสพนักงาน" name="employee_id" rules={[{required: true,message: ""}]}>
                        <Input className="w-100" />
                      </Form.Item>
                </Col>
                <Col xs={{ span: 12 }} lg={{ span: 6 }}>
                      <Form.Item label="ฝ่าย" name="employee_team" rules={[{required: true, message: ""}]}>
                        <Select
                          defaultValue={employee_team[0]}
                          className="w-100"
                          onChange={handleTeamChange}
                          options={employee_team.map((item) => ({ label: item, value: item }))}
                        />
                      </Form.Item>
                </Col>
                <Col xs={{ span: 12 }} lg={{ span: 6 }}>
                      <Form.Item label="ทีม" name="employee_department" rules={[{required: true, message: ""}]}>
                        {/*<Select
                          className="w-100"
                          value={secondDepartment}
                          onChange={onSecondDepartmentChange}
                          options={department.map((item) => ({ label: item, value: item }))}
                        />*/}
                        <Input className="w-100" />
                      </Form.Item>
                </Col>
                <Col xs={{ span: 24 }} lg={{ span: 4 }}>
                      <Form.Item label="(นาย/นาง/นางสาว)" name="prefix" rules={[{required: true,message: ""}]}>
                        <Select placeholder="โปรดเลือก" className="w-100" options={prefix} />
                      </Form.Item>
                </Col>
                <Col xs={{ span: 24 }} lg={{ span: 10 }}>
                      <Form.Item label="ชื่อ (ภาษาไทย)" name="first_name" rules={[{required: true,message: ""}]}>
                        <Input className="w-100" />
                      </Form.Item>
                </Col>
                <Col xs={{ span: 24 }} lg={{ span: 10 }}>
                      <Form.Item label="นามสกุล (ภาษาไทย)" name="last_name" rules={[{required: true,message: ""}]}>
                        <Input className="w-100" />
                      </Form.Item>
                </Col>
                <Col xs={{ span: 24 }} lg={{ span: 4 }}>
                </Col>
                <Col xs={{ span: 24 }} lg={{ span: 10 }}>
                      <Form.Item label="ชื่อ (ภาษาอังกฤษ)" name="first_name_en" rules={[{required: true,message: ""}]}>
                        <Input className="w-100" />
                      </Form.Item>
                </Col>
                <Col xs={{ span: 24 }} lg={{ span: 10 }}>
                      <Form.Item label="นามสกุล (ภาษาอังกฤษ)" name="last_name_en" rules={[{required: true,message: ""}]}>
                        <Input className="w-100" />
                      </Form.Item>
                </Col>
                <Col xs={{ span: 24 }} lg={{ span: 4 }}>
                </Col>
                <Col xs={{ span: 24 }} lg={{ span: 20 }}>
                      <Form.Item label="เลขที่บัตรประจำตัวประชาชน" name="card_id">
                        <ReactCodeInput type="number" fields={13} {...propsCodeInput} isValid={isCardIdValid}/>
                      </Form.Item>
                </Col>
              </Row>
            </Card>
          </Col>
          <Col span={24} align="right">
            <Button type="primary" htmlType="submit">
              Next
            </Button>
          </Col>
        </Row>
      </Form>
    )
  }


  const columns = [
    {
      title: "ทางเลือกการลงทุน",
      dataIndex: "choice",
      width: 180,
      render: (item,record) => (
          <Radio value={record.key}>{item}</Radio>
      ),
    },

    {
      title: "สัดส่วนเงินนำส่งในแต่ละนโยบาย",
      dataIndex: "ratio",
    },
  ];

  function onFinishInvest(values) {
    if(values.plan){
      message.success('complete step 2')
      setFormDataInvest(values)
      setStepForm(3)
      setTimeout(() => {
        setBenefitOpen(true)
      }, 1000)
    }else{
      message.error('กรุณาเลือกนโยบายการลงทุน')
    }
  }

  const AlertMessage = () => {
      return (<>
          <Text strong>คําเตือน</Text>
          <ul>
            <li>
              กรณีที่เลือกลงทุนในนโยบายการลงทุน/ทางเลือกการลงทุนที่มีระดับความเสี่ยงสูงกว่าระดับความเสี่ยงที่สมาชิกรับได้ ซึ่งในบางปีอาจทําให้ผลตอบแทนมีความผันผวนจนทําให้มูลค่าเงินกองทุนที่ได้รับไม่เป็นไปตามคาดหวัง
            </li>
            <li>
              กรณีที่เลือกลงทุนในนโยบายการลงทุน/ทางเลือกการลงทุนที่มีความเสี่ยงต่ํากว่าระดับความเสี่ยงที่สมาชิกรับได้อาจทําให้สมาชิกได้รับผลตอบแทนตํ่าลงจนส่งผลให้มูลค่าเงินกองทุนไม่เพียงพอที่จะใช้จ่ายเมื่อยามเกษียณอายุ
            </li>
          </ul>
          </>
      )
  }


  const [investOpen, setInvestOpen] = useState(false);
  const InvestRef1 = useRef(null);
  const InvestRef2 = useRef(null);
  const InvestRef3 = useRef(null);

  const stepsInvest = [
    {
      title: 'เลือกนโยบายการลงทุน',
      description: 'เลือกนโยบายการลงทุนที่ท่านต้องการได้เพียง 1 แบบเท่านั้น',
      target: () => InvestRef1.current,
    },
  ];


  const RenderFormInvest = () => {
    return (
      <Form form={form_invest} className="inline_form" onFinish={onFinishInvest}>
        <Row gutter={[24, 24]}  className="mb-10">
          <Col span={24}>
            การเลือกลงทุนในนโยบายการลงทุน/ทางเลือกการลงทุนของสมาชิกโดยไม่ทําแบบประเมินความเสี่ยงจะทําให้สมาชิกกองทุนไม่ทราบถึง 
            ระดับความเสี่ยงที่ยอมรับได้ของตนเองอันอาจนําไปสู่การเลือกนโยบายการลงทุน/ทางเลือกการลงทุนในกองทุนสํารองเลี้ยงชีพท่ีไม่เหมาะสม
          </Col>

          <Col span={24} align="center">
            <Title level={5}>
            แบบฟอร์มแจ้งความประสงค์เลือกนโยบายการลงทุน /ทางเลือกการลงทุน <br />
            กองทุนสํารองเลี้ยงชีพทิสโก้มาสเตอร์ร่วมทุนซึ่งจดทะเบียนแล้ว <br />
            เฉพาะส่วนบริษัท {formDataCompany.company_name} จำกัด
            </Title>
          </Col>
          <Col span={24}>
            <Card>
              <Row gutter={[12, 12]}>

                <Col span={24}>
                  <div className="custom-form">
                    <Typography>
                      <Text className="text-begin">
                        โดยหนังสือฉบับนี้ ข้าพเจ้า
                      </Text>
                      <Text strong> {formDataCompany.prefix} </Text>
                      <Text strong> {formDataCompany.first_name} </Text>
                      <Text strong> {formDataCompany.last_name} </Text>

                      <Text> เลขที่บัตรประจำตัวประชาชน </Text> 
                      <Text strong>{formDataCompany.card_id} </Text>

                      <Text> รหัสพนักงาน </Text> 
                      <Text strong>{formDataCompany.employee_id} </Text>

                      <Text> ฝ่าย </Text> 
                      <Text strong>{formDataCompany.employee_team} </Text>

                      <Text> แผนก </Text> 
                      <Text strong>{formDataCompany.employee_department} </Text>

                    </Typography>
                    <Typography>
                      ประสงค์จะเลือกนโยบายการลงทุน/ทางเลือกการลงทุน
                      เพื่อนำส่งเงินเข้ากองทุนและโอนเงินเข้ากองทุน (ถ้ามี)
                      ภายใต้นโยบายการลงทุน/ทางเลือกการลงทุน ตามรายละเอียดดังนี้
                    </Typography>
                  </div>
                </Col>

                <Col span={24}>
                  <ol type="1">
                    <li>
                      ข้าพเจ้าได้ทำแบบประเมินความเสี่ยงเพื่อความเหมาะสมในการเลือกนโยบายการลงทุน/ทางเลือกการลงทุน
                      ("แบบประเมินความเสี่ยง" หรือ "Member Risk Profile")
                      และได้รับคำแนะนำการลงทุนจากบริษัทจัดการโดยผ่านคณะกรรมการกองทุนตามข้อมูลที่บริษัทจัดการได้จัดเตรียมไว้ให้
                      ทั้งนี้
                      ในกรณีที่ข้าพเจ้าปฏิเสธไม่ให้ข้อมูลตามแบบประเมินความเสี่ยงดังกล่าว
                      หรือให้ข้อมูลไม่ครบถ้วน
                      ให้ถือว่าข้าพเจ้าไม่ประสงค์จะรับคำแนะนำการลงทุนตามข้อมูลที่บริษัทจัดการได้จัดเตรียมไว้
                      และประสงค์จะตัดสินใจการลงทุนด้วยข้าพเจ้าเอง
                    </li>
                    <li>
                      ข้าพเจ้าได้รับทราบและเข้าใจถึงนโยบายการลงทุนและทางเลือกการลงทุนที่ข้าพเจ้าเลือกเป็นอย่างดีแล้ว
                      และตกลงยอมรับความเสี่ยงในการเพิ่มขึ้นหรือลดลงของอัตราผลตอบแทนที่จะเกิดขึ้นของนโยบายการลงทุนและทางเลือกการลงทุนที่ข้าพเจ้าได้เลือกไว้ดังกล่าวข้างต้น
                    </li>
                    <li>
                      ข้าพเจ้าประสงค์จะนำเงินเข้ากองทุนภายใต้นโยบายการลงทุน/ทางเลือกการลงทุน
                      ดังนี้ (โปรดทำเครื่องหมาย <CheckOutlined />
                      เพื่อเลือกนโยบายการลงทุน/ทางเลือกการลงทุน)

                      <Form.Item name="plan">
                      <Radio.Group >
                      <Table
                        size="small"
                        rowKey="choice"
                        columns={columns}
                        dataSource={plan}
                        pagination={false}
                        className="mt-5"
                      />
                      </Radio.Group>
                      </Form.Item>
                      <Alert message={<AlertMessage/>} type="error" className="mb-5 mt-5" />
                    </li>
                  </ol>

                  <ol type="1">
                    <li>
                      ข้าพเจ้ายอมรับว่ากรณีที่ข้าพเจ้าเลือกทางเลือกการลงทุนที่มีการนําเงินเข้ามากกว่า 1 นโยบายการลงทุน 
                      อัตราส่วนของยอดเงินสุทธิระหว่างนโยบายจะเปลี่ยนแปลงไปตามภาวะตลาดการลงทุน
                    </li>
                    <li>
                      ข้าพเจ้ารับทราบและตกลงจะปฏิบัติตามข้อกําหนดในข้อบังคับกองทุนท่ีเกี่ยวกับกองทุนหลายนโยบายการลงทุนรวมถึงสิทธิในการ 
                      เปลี่ยนแปลงทางเลือกการลงทุนและ/หรือรูปแบบการนําส่งเงินเข้ากองทุนตามที่คณะกรรมการกองทุนประกาศกําหนด
                    </li>
                    <li>
                    ข้าพเจ้ารับทราบว่าการเลือกนโยบายการลงทุนตามเอกสารฉบับนี้จะมีผลตั้งแต่วันที่ได้รับอนุมัติจากคณะกรรมการกองทุน
                    </li>
                    <li>
                    ในกรณีที่ข้าพเจ้าเลือกลงทุนในนโยบายการลงทุน / ทางเลือกการลงทุนที่มีระดับความเสี่ยงสูงหรือตํ่ากว่าระดับความเส่ียงที่ข้าพเจ้ารับได้ 
                    หรือกรณีท่ีข้าพเจ้าเลือกลงทุนในนโยบายการลงทุนที่มีการลงทุนในต่างประเทศซึ่งมีความเส่ียงจากอัตราแลกเปล่ียน เมื่อพิจารณาตาม 
                    ข้อมูลในแบบประเมินความเส่ียงแล้วข้าพเจ้าขอยืนยันว่าได้รับทราบคําเตือนซึ่งบริษัทจัดการจัดเตรียมไวใ้ห้แล้วและตกลงยอมรับความ
                    เส่ียงที่อาจจะเกิดข้ึน และขอยืนยันว่าข้าพเจ้ามีความประสงค์จะลงทุนตามนโยบายการลงทุน/ทางเลือกการลงทุน กําหนดไว้ข้างต้น
                    </li>

                  </ol>
                </Col>
              </Row>
            </Card>
          </Col>
          <Col span={24} align="right">
       
              <Button onClick={()=>setStepForm(1)} className="mr-5">
                Back
              </Button>
              <Button type="primary" htmlType="submit">
                Next
              </Button>
    
          </Col>
        </Row>
        <Tour open={investOpen} onClose={() => setInvestOpen(false)} steps={stepsInvest} />
      </Form>
    );
  }


  const [benefitOpen, setBenefitOpen] = useState(false);
  const BenefitRef1 = useRef(null);
  const BenefitRef2 = useRef(null);
  const BenefitRef3 = useRef(null);

  const stepsBenefit = [
    {
      title: 'เลือก % นำส่ง',
      description: 'นำส่งเงินสะสมในอัตราร้อยละกี่ % ของค่าจ้าง',
      target: () => BenefitRef1.current,
    },
    {
      title: 'เพิ่มผู้รับผลประโยชน์',
      description: 'กดเพิ่มผู้รับผลประโยชน์ และกรอกแบบฟอร์มให้ครบถ้วน',
      target: () => BenefitRef2.current,
    },
    {
      title: 'รายชื่อผู้รับผลประโยชน์',
      description: 'รายการแสดงรายชื่อผู้รับผลประโยชน์',
      target: () => BenefitRef3.current,
    },
  ];

  function onFinishBenefit(values) {
    console.log('onFinish add to mongo==>',{...values,beneficiary})
    if (beneficiary.length === 0){
      message.error("โปรดเพิ่มผู้รับผลประโยชน์");
    } else {
      var total = beneficiary.reduce((accumulator, object) => {
        return accumulator + object.percent;
      }, 0)
      if (total !== 100) {
        message.error('ส่วนแบ่งต้องรวมแล้วได้ 100%')
      }
      else{
        setLoading(true)
        var body = {
         method: 'post',
         headers: {'Content-Type':'application/json'},
         body: JSON.stringify({...formDataCompany,...formDataInvest,...values,beneficiary})
        }
        console.log(body)
        fetch('/api/provident_fund/',body)
          .then(async response => {
            const data = await response.json();
            console.log('data=====>',data)
            if(!response.ok) {
              const error = (data && data.message) || response.statusText;
              return Promise.reject(error);
            }else{
              message.success('Complete')
              setStepForm(4)
              setLoading(false)
            }
          }).catch(error => {
            message.error('กรุณาลองใหม่อีกครั้ง')
            setLoading(false)
            console.log('error=>call api',error)
          })
      }
    }
  }

  function onFinishAdd(values) {
    setBeneficiary((beneficiary) => [...beneficiary, values]);
    setTotal(total + values.percent);
    onClose();
  }

  function onOpen() {
    form_benefit_edit_drawer.resetFields();
    if (beneficiary.length < 4) {
      setOpen(true);
    } else {
      message.error("ผู้รับผลประโยชน์สูงสุดไม่เกิน 4 คน")
    }
  }

  function onClose() {
    setOpen(false);
    form_benefit_drawer.resetFields();
  }

  function onOpenEdit(id,data) {
      setFormDataBenefitIndex(id)
      setFormDataBenefitEdit(data)
      form_benefit_edit_drawer.setFieldsValue(data);
      setOpenEdit(true);
  }

  function onCloseEdit() {
    setOpenEdit(false);
  }


  function onFinishEdit(values) {
    let newData = [...beneficiary]
    newData[formDataBenefitIndex] = values

    var total = newData.reduce((accumulator, object) => {
      return accumulator + object.percent;
    }, 0)
    if(total<=100){
      setBeneficiary(newData);
      setTotal(total);
      onCloseEdit();
    }else{
      message.error('ส่วนแบ่งต้องรวมแล้วได้ 100%')
    }
  }
  function onRemove() {
    let newData = [...beneficiary]
    newData.splice(formDataBenefitIndex, 1);
    setBeneficiary(newData);
    setTotal(beneficiary.reduce((accumulator, object) => {
      return accumulator + object.percent;
    }, 0));
    onCloseEdit();
  }

  const RenderFormBenefit = () => {
    return (
    <>
      <Form form={form_benefit} className="inline_form" onFinish={onFinishBenefit}>
        <Row gutter={[24, 24]} className="mb-10">
          <Col span={24} align="center">
            <Title level={5}>ใบสมัครเข้าเป็นสมาชิกกองทุน สํารองเลี้ยงชีพ</Title>
          </Col>
          <Col span={24}>
            <Card>
              <Row gutter={[12, 12]}>
                <Col span={24}>
                  <div className="custom-form">
                    <Typography>
                      <Text className="text-begin">
                        โดยหนังสือฉบับนี้ ข้าพเจ้า 
                      </Text>
                      <Text strong> {formDataCompany.prefix} </Text>
                      <Text strong> {formDataCompany.first_name} </Text>
                      <Text strong> {formDataCompany.last_name} </Text>
                      ขอสมัครเข้าเป็นสมาชิกกองทุนสำรองเลี้ยงชีพ
                      ทิสโก้มาสเตอร์ร่วมทุน ซึ่งจดทะเบียนแล้ว{" "}
                      <Text> เฉพาะส่วนบริษัท </Text> 
                      <Text strong>{formDataCompany.company_name} จำกัด</Text>
                    </Typography>
                    <Typography>(ต่อไปนี้จะเรียกว่า กองทุน) โดย</Typography>
                  </div>
                </Col>
                <Col span={24}>
                  <ol type="1">
                    <li>
                      ข้าพเจ้าได้ทราบข้อบังคับของกองทุน
                      และสิทธิหน้าที่ของข้าพเจ้าดีแล้ว
                      และตกลงที่จะปฏิบัติตามทุกประการ
                    </li>
                    <li>
                      ในการนำส่งเงินสะสมของข้าพเจ้าเพื่อส่งเข้ากองทุน
                      ข้าพเจ้าขอให้บริษัทนายจ้างนำส่งตามอัตราที่กำหนดในใบแจ้งอัตราเงินสะสม
                      โดยหักจากค่าจ้างของข้าพเจ้าทุก ๆ เดือน
                    </li>
                    <li>
                      <div className="custom-form">
                        <Text>
                          ในการนำส่งเงินสะสมของข้าพเจ้าเพื่อส่งเข้ากองทุน
                          ซึ่งข้อบังคับกองทุนของบริษัทฯ
                          สมาชิกสามารถเลือกจ่ายเงินสะสมในอัตราร้อยละ 5 หรือ 10
                          หรือ 15 ของค่าจ้าง ทั้งนี้
                          ข้าพเจ้ามีความประสงค์ที่จะให้บริษัทนำส่งเงินสะสมในอัตราร้อยละ{" "}
                        </Text>
                        <span ref={BenefitRef1}>
                        <Form.Item name="rate" rules={[{required: true,message: ""}]}>
                          <Select placeholder="โปรดเลือก" size="small" style={{width: 100}} options={percent}/>
                        </Form.Item>
                        </span>
                        <Text>
                          ของค่าจ้าง โดยหักจากค่าจ้างของข้าพเจ้าทุก ๆ เดือน
                        </Text>
                      </div>
                    </li>
                    <li>

                        <Text>
                          ในกรณีที่ข้าพเจ้าเสียชีวิต
                          ข้าพเจ้าขอระบุบุคคลผู้มีสิทธิรับผลประโยชน์ทั้งสิ้นของข้าพเจ้า
                          ตามรายละเอียดดังต่อไปนี้
                        </Text>

                        <Table
                          size="small"
                          className="mt-5 mb-5"
                          ref={BenefitRef3}
                          onRow={(record, rowIndex) => {
                            return {
                              onClick:(event) => {
                                console.log('click==>',rowIndex,record)
                                onOpenEdit(rowIndex,record)
                              }, 
                            };
                          }}
                          columns={[
                            {
                              title: "ลำดับ",
                              dataIndex: "",
                              align: "center",
                              render: (_, __, index) => <Text>{`${index + 1}.`}</Text>,
                            },
                            {
                              title: "รายละเอียดผู้รับผลประโยชน์",
                              key: "",
                              dataIndex: "",
                              render: (_, record) => (
                                <Row gutter={[6, 6]}>
                                  <Col
                                    span={12}
                                  >{`ชื่อ-สกุล : ${record.name}`}</Col>
                                  <Col span={12}>{`ความสัมพันธ์ : ${record.relationship}`}</Col>
                                  <Col span={24}>{`ที่อยู่ : ${record.address}`}</Col>
                                </Row>
                              ),
                            },
                            { title: "เบอร์ติดต่อ", dataIndex: "phone" },
                            { title: "ส่วนแบ่ง(%)", dataIndex: "percent", align: "center" },
                          ]}
                          dataSource={beneficiary}
                          pagination={false}
                          footer={() => (
                            <div className="table-footer">{`รวมทั้งสิ้น : ${total}%`}</div>
                          )}
                        />
                        <Button block type="dashed" onClick={onOpen} className="mb-5" ref={BenefitRef2}>
                          + เพิ่มผู้รับผลประโยชน์
                        </Button>
              
                      <p><Text strong>หมายเหตุ :</Text> ผู้รับผลประโยชน์สามารถมีได้มากกว่า 2 ท่าน โดยส่วนแบ่งรวมกันทั้งสิ้นต้องเท่า กับ 100%</p>
       
                    </li>

                    <li>
                      การเปลี่ยนแปลงอัตราเงินสะสมรายละเอียดเกี่ยวกับผู้รับผลประโยชน์
                      รวมทั้งเปอร์เซ็นต์ส่วนแบ่งตามข้อ 3. ข้างต้นนั้น
                      จะต้องกระทำโดยแจ้งเป็นลายลักษณ์อักษรต่อคณะกรรมการกองทุน
                    </li>
                    <li>
                      ข้าพเจ้าตกลงยินยอมให้บริษัทหลักทรัพย์จัดการกองทุนทิสโก้(จํากัด)(“บริษัทจัดการ”)นําข้อมูลส่วนบุคคลของข้าพเจ้าอัน 
                      หมายความรวมถึงแตไ่ม่จํากัดเฉพาะชื่อท่ีอยู่เบอร์โทรศัพท์และรายละเอียดเก่ียวกับเงินเดือนของขา้พเจ้าไปใช้เพ่ือประโยชน์ในการบริหาร 
                      จัดการกองทุนและเพื่อประโยชน์ของข้าพเจ้าในฐานะสมาชิกกองทุน รวมท้ังยินยอมให้บริษัทจัดการเก็บรักษาข้อมูลส่วนบุคคลดังกล่าวไว้ 
                      ภายในระยะเวลาที่กฎหมายกําหนด
                    </li>
                  </ol>
                </Col>
              </Row>
            </Card>
          </Col>
          <Col span={24} align="right">
      
              <Button onClick={()=>{
                setStepForm(2)
              }} className="mr-5">
                Back
              </Button>
              <Button type="primary" htmlType="submit">
                Submit
              </Button>
     
          </Col>
        </Row>
      </Form>

      <Tour open={benefitOpen} onClose={() => setBenefitOpen(false)} steps={stepsBenefit} />

      <Drawer title="แก้ไขผู้รับผลประโยชน์" open={openEdit} closable={false}>
        <Form layout="vertical"  form={form_benefit_edit_drawer} onFinish={onFinishEdit}>
          <Space direction="vertical" size="middle">
            <Form.Item label="ชื่อ นามสกุล" name="name" rules={[{required: true,message: ""}]}>
              <Input style={{ width: 300 }} />
            </Form.Item>
            <Form.Item label="ความสัมพันธ์" name="relationship" rules={[{required: true,message: ""}]}>
              <Input style={{ width: 300 }} />
            </Form.Item>
            <Form.Item label="ที่อยู่" name="address" rules={[{required: true,message: ""}]}>
              <Input style={{ width: 300 }} />
            </Form.Item>
            <Form.Item label="เบอร์ติดต่อ" name="phone" rules={[{required: true,message: ""}]}>
              <Input style={{ width: 300 }} />
            </Form.Item>
            <Form.Item label="ส่วนแบ่ง (%)" name="percent" rules={[{required: true,message: ""}]}>
              <InputNumber style={{ width: 300 }} max={100} min={1} />
            </Form.Item>
            <Space>
              <Button htmlType="button" onClick={onCloseEdit}>
                Cancel
              </Button>
              <Button type="primary" htmlType="submit">
                Submit Edit
              </Button>
              <Button type="link" onClick={onRemove}>
                Delete
              </Button>
            </Space>
          </Space>
        </Form>
      </Drawer>


      <Drawer title="เพิ่มผู้รับผลประโยชน์" open={open} closable={false}>
        <Form layout="vertical" form={form_benefit_drawer} onFinish={onFinishAdd}>
          <Space direction="vertical" size="middle">
            <Form.Item label="ชื่อ นามสกุล" name="name" rules={[{required: true,message: ""}]}>
              <Input style={{ width: 300 }} />
            </Form.Item>
            <Form.Item label="ความสัมพันธ์" name="relationship" rules={[{required: true,message: ""}]}>
              <Input style={{ width: 300 }} />
            </Form.Item>
            <Form.Item label="ที่อยู่" name="address" rules={[{required: true,message: ""}]}>
              <Input style={{ width: 300 }} />
            </Form.Item>
            <Form.Item label="เบอร์ติดต่อ" name="phone" rules={[{required: true,message: ""}]}>
              <Input style={{ width: 300 }} />
            </Form.Item>
            <Form.Item label="ส่วนแบ่ง (%)" name="percent" rules={[{required: true,message: ""}]}>
              <InputNumber style={{ width: 300 }} max={100 - total} min={1} />
            </Form.Item>
            <Space>
              <Button htmlType="button" onClick={onClose}>
                Cancel
              </Button>
              <Button type="primary" htmlType="submit">
                Submit
              </Button>
            </Space>
          </Space>
        </Form>
      </Drawer>
    </>
    )
  }

  const RenderFormResult = () => {
    return (
       <Result
          status="success"
          title="ทำการส่งแบบฟอร์มสำเร็จ"
          extra={[
            <a href='/process/auth/logout/'><Button type="primary">
              Done and Logout
            </Button></a>
          ]}
        />
      )
    }


  return mounted ? (
    <Spin spinning={loading} delay={500}>
      <Layout>
          <Header className="w-100" theme={'light'}>
            <Space className="w-100 justify-space-between">

                <Space size="middle"  align="center">
                  <a className="App-brand"><img src={'/logo.png'} style={{ height: 39 }}/></a>
                </Space>
                <Space size="middle"  align="center">
                      <Dropdown overlay={(
                <Menu>
                    <Menu.Item key="1">
                        <a href='/process/auth/logout/'>Logout</a>
                    </Menu.Item>
                </Menu>
            )} trigger={["click"]}>
                          <a className="ant-dropdown-link" onClick={(e) => e.preventDefault()}>
                              <Space>
                                  <Avatar src={authData.user.avatar} className="mr-2" />
                                  <div className="mr-2">
                                      <h5 className="mb-0">
                                          {authData.user.name}
                                      </h5>
                                  </div>
                              </Space>
                          </a>
                      </Dropdown>
                </Space>
            </Space>
          </Header>

          <Layout className="wrap">
              {(mounted && stepForm ==1) && <RenderFormCompany />}
              {(mounted && stepForm ==2) && <RenderFormInvest />}
              {(mounted && stepForm ==3) && <RenderFormBenefit />}
              {(mounted && stepForm ==4) && <RenderFormResult />}
          </Layout>
      </Layout>
    </Spin>
  ) : (<></>);
};

export default Page;
