import { useRef, useEffect, useState } from "react";
import {
  Row,
  Col,
  Card,
  Button,
  Form,
  Typography,
  Select,
  Input,
  Table,
  Space,
  Drawer,
  InputNumber,
  Steps,
  Checkbox,
  Layout,
  Spin,
  Avatar,
  Radio,
  Alert,
  Menu,
  Tour,
  Result,
  Dropdown,
  Descriptions,
  Modal,
  message
} from "antd";
import moment from 'moment'
import { CheckOutlined, GoogleOutlined, ExclamationCircleOutlined } from "@ant-design/icons";
import { useParams, useNavigate , Link } from 'react-router-dom'
import { company, prefix } from "./index.const.jsx";

const { confirm } = Modal;
const { Step } = Steps;
const { Title, Text, Paragraph } = Typography;
const { Header, Content, Footer  } = Layout;

const Page = () => {

  const navigate = useNavigate();
  const id = '64b68de4ffc480455d0a3386';

  const [form_read] = Form.useForm();
  const [form_sign] = Form.useForm();
  const [form_confirm] = Form.useForm();

  const [loading, setLoading] = useState(false);
  const [stepForm, setStepForm] = useState(1);


  const [mounted, setMounted] = useState(false);
  const [authData, setAuthData] = useState(false);
  const [checked, setChecked] = useState(false);
  const [checkedConfirm, setCheckedConfirm] = useState(false);
  const [companyName, setCompanyName] = useState('');
  const [formDataSign, setFormDataSign] = useState({});

  const [formData, setFormData] = useState({name:'',drive:[]});



  const company_index = {
        "BA":"BA",
        "BC":"BCGH",
        "BO":"BO",
        "BM":"BM",
        "BV":"BV",
        "BI":"BI",
        "BW":"BWT",
        "BP":"BP",
        "BB":"BBT",
    }


  const fetchForm = (callback) => {
      fetch('/api/consent/'+id)
        .then(async response => {
          const data = await response.json();
          console.log('data=====>',data)
          if(!response.ok) {
            const error = (data && data.message) || response.statusText;
            return Promise.reject(error);
          }
          if(data.status == false) {
             setStepForm(4)
             callback()
          } else {
            setFormData(data.data)
            callback()
          }
        }).catch(error => {
          setStepForm(4)
          callback()
        })
  }

  useEffect(() => {
      setMounted(false)
      fetchForm(()=>{
        fetch('/process/auth/check')
          .then(async response => {
            const data = await response.json();
            console.log('data=====>',data)
            if(!response.ok) {
              const error = (data && data.message) || response.statusText;
              return Promise.reject(error);
            }
            if(data.status == false) {
               navigate("/?form=/consent/"+id);
            } else {
              var _company = company.filter(item=>item.key==company_index[data.user.emp_id.slice(0,2)])[0]
              console.log('_company===>',_company)
              
              setCompanyName(_company.label_en)
              setAuthData(data)
              setFormDataSign({
                sign_date:moment().format('YYYY-MM-DD'),
                company_name:_company.label_en,
                company_key:_company.key,
                company:{},
                name_en:'',
                name_th:'',
                team:'',
                position:'',
                email:data.user.email,
                employee_id:data.user.emp_id
              })

              setMounted(true)
            }
          }).catch(error => {
            console.log('error=>call api',error)
          })
      })
   
  },[])

  const onChange = (e) => {
    console.log(`checked = ${e.target.checked}`);
    setChecked(e.target.checked)
  };

  const onChangeConfirm = (e) => {
    console.log(`checked = ${e.target.checked}`);
    setCheckedConfirm(e.target.checked)
  };

  const onFinishSign = (values) => {
    if(checked==true){
      values.company = formData.drive.filter(item=>item.key==formDataSign.company_key)[0]
      console.log('_company_data===>',values,formDataSign.company_key)
      setFormDataSign({...formDataSign,...values})
      console.log({...formDataSign,...values})

      message.success('Create Electronic signature complete')
      setStepForm(2)

    }
  }

  const RenderFormSign = () => {
    return (
    <>
      <Form form={form_sign} layout="vertical" onFinish={onFinishSign} initialValues={formDataSign}>
        <Row gutter={[24, 24]} className="mb-10">
          <Col span={24}>
              <Card className="form-law">
                  <Row gutter={[24, 32]}>
                    <Col span={24} align="right">
                      <Title level={4} underline align="center">
                        Food Credit Consent
                      </Title>
                      <Text>
                        Date: {formDataSign.sign_date}
                      </Text>
                    </Col>
                    <Col span={24}>
                      <Space direction="vertical" style={{ display: "flex" }}>
                        <Card title="DETAILS OF EMPLOYEE">
                          <Row gutter={[12, 12]}>
                            <Col xs={{ span: 24 }} lg={{ span: 24 }}>
                              <Form.Item name="company_name" label="Name of Company (Company):"  rules={[{required: true,message: ""}]}>
                                <Input className="w-100"  placeholder="Company name Co.,Ltd."/>
                              </Form.Item>
                            </Col>
                            <Col xs={{ span: 24 }} lg={{ span: 24 }}>
                              <Form.Item name="name_en"  label="Full Name of employee (EN):" rules={[{required: true,message: ""}]}>
                                <Input className="w-100" placeholder="Name Surname (EN)"/>
                              </Form.Item>
                            </Col>
                            <Col xs={{ span: 24 }} lg={{ span: 12 }}>
                              <Form.Item name="email" label="Email:" rules={[{required: true,message: ""}]}>
                                <Input className="w-100" />
                              </Form.Item>
                            </Col>
                                <Col xs={{ span: 24 }} lg={{ span: 12 }}>
                              <Form.Item name="employee_id" label="Employee ID:(Ex.BCGH001)" rules={[{required: true,message: ""}]}>
                                <Input className="w-100" />
                              </Form.Item>
                            </Col>
                            <Col xs={{ span: 24 }} lg={{ span: 12 }}>
                              <Form.Item name="team"  label="Team:" rules={[{required: true,message: ""}]}>
                                <Input className="w-100" placeholder="Team"/>
                              </Form.Item>
                            </Col>
                            <Col xs={{ span: 24 }} lg={{ span: 12 }}>
                              <Form.Item name="position" label="Position:" rules={[{required: true,message: ""}]}>
                                <Input className="w-100"  placeholder="Position"/>
                              </Form.Item>
                            </Col>
                          </Row>
                        </Card>
                      </Space>
                    </Col>
                  </Row>
              </Card>
          </Col>
          <Col span={24} align="left">
                <Checkbox onChange={onChange}>I have filled in the information truthfully. All of the above is complete.</Checkbox>
          </Col>
          <Col span={24} align="left">
      
              <Button type="primary" htmlType="submit" disabled={!checked}>
                Next
              </Button>
     
          </Col>
        </Row>
      </Form>

    </>
    )
  }



  const RenderFormConfirm = () => {
    return (
    <>
      <Form form={form_confirm} layout="vertical" onFinish={onFinishConfirm}>
        <Row gutter={[24, 24]} className="mb-10">
          <Col span={24}>
              <Card className="form-law">
                  <Row gutter={[24, 32]}>
                    <Col span={24} align="right">
                      <Title level={4} underline align="center">
                        Food Credit Consent
                      </Title>
                      <Text>
                        Date: {formDataSign.sign_date}
                      </Text>
                    </Col>
                    <Col span={24}>
                      <Space direction="vertical" style={{ display: "flex" }}>
                        <Title level={5} underline  align="center">Letter of Consent</Title>


                        <Paragraph>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;I, <strong>{formDataSign.name_th}</strong> Position <strong>{formDataSign.position}</strong> Department  <strong>{formDataSign.team}</strong>  Employee Number <strong>{formDataSign.employee_id}</strong> (the “Employee”) as the Employee of Bitkub Capital Group Holdings Company Limited ("Company"), am aware that due to the economic downturn, it is essential for the company to reduce expenses, including monthly food credits.</Paragraph>
                        <Paragraph>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;The Employee fully understands the necessity of the company as stated above and voluntarily agrees to reduce the monthly food credit amount from 3,000.00 baht (three thousand baht) to 1,200.00 baht (one thousand and two hundred baht) beginning on August 1, 2023, and consents to refrain from asserting any claims against the Company.</Paragraph>
                        <Paragraph>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;IN WITNESS WHEREOF, the Employee voluntarily consents to the above statement by signing this Letter of Consent.</Paragraph>


                      </Space>
                    </Col>
                    <Col span={10} align="start"></Col>
                    <Col span={8}>
                          <Space direction="vertical" align="center">
                            <Text>(____{formDataSign.name_en}____)</Text>
                            <Text>
                              (........{formDataSign.name_th}.........)
                            </Text>
                          </Space>
                    </Col>
                    <Col span={6} align="start">
                          <Space direction="vertical" align="start">
                            <Text>Employee</Text>
                            <Text></Text>
                          </Space>
                    </Col>
                    <Col span={10} align="start"></Col>
                    <Col span={8}>
                          <Space direction="vertical" align="center" className="w-70">
                            <Text>(_______Suchart Pawasiriporn________)</Text>
                            <Text>(Suchart Pawasiriporn)</Text>
                            <Text>Group Chief People Officer</Text>
                          </Space>
                    </Col>
                    <Col span={6} align="start">
                          <Space direction="vertical" align="start" className="w-30">
                            <Text>Witness</Text>
                            <Text></Text>
                            <Text></Text>
                            <Text></Text>
                          </Space>
                    </Col>
                  </Row>
              </Card>
          </Col>
          <Col span={24} align="left">
                <Checkbox onChange={onChangeConfirm}>I have read and understand the details Including having to fill in the information truthfully All of the above is complete.</Checkbox>
          </Col>
          <Col span={24} align="left">
      
              <Button type="primary" onClick={onFinishConfirm} disabled={!checkedConfirm}>
                Confirm electronic signature
              </Button>
     
          </Col>
        </Row>
      </Form>

    </>
    )
  }

  function onFinishConfirm() {

    confirm({
      icon: <ExclamationCircleOutlined />,
      title:"Confirm ?",
      content: 'Confirm electronic signature?',
      onOk() {
        setLoading(true)
        var body = {
         method: 'post',
         headers: {'Content-Type':'application/json'},
         body: JSON.stringify(formDataSign)
        }
        console.log(body)
        fetch('/api/consent/'+id,body)
          .then(async response => {
            const data = await response.json();
            console.log('data=====>',data)
            if(!response.ok) {
              const error = (data && data.message) || response.statusText;
              return Promise.reject(error);
            }else{
              message.success('Complete')
              setStepForm(3)
              setLoading(false)
            }
          }).catch(error => {
            message.error('Please try again.')
            setLoading(false)
          })
      },
      onCancel() {
        console.log('Cancel');
      },
    });

  }

  function onBack() {
      setStepForm(2)
  }


  const RenderFormResult = () => {
    return (
       <Result
          status="success"
          title="Successfully submitted the form"
          extra={[
            <a href='/process/auth/logout/'><Button type="primary">
              Done and Logout
            </Button></a>
          ]}
        />
      )
  }


  const RenderFormResultEmpty = () => {
    return (
       <Result
          title="form not found"
          extra={[
            <a href='/process/auth/logout/'><Button type="primary">
              Done and Logout
            </Button></a>
          ]}
        />
      )
  }



  return mounted ? (
    <Spin spinning={loading} delay={500}>
      <Layout>
          <Header className="w-100" theme={'light'}>
            <Space className="w-100 justify-space-between">

                <Space size="middle"  align="center">
                  <a className="App-brand"><img src={'/logo.png'} style={{ height: 39 }}/></a>
                </Space>
                <Space size="middle"  align="center">
                      <Dropdown overlay={(
                <Menu>
                    <Menu.Item key="1">
                        <a href='/process/auth/logout/'>Logout</a>
                    </Menu.Item>
                </Menu>
            )} trigger={["click"]}>
                          <a className="ant-dropdown-link" onClick={(e) => e.preventDefault()}>
                              <Space>
                                  <Avatar src={authData.user.avatar} className="mr-2" />
                                  <div className="mr-2">
                                      <h5 className="mb-0">
                                          {authData.user.name}
                                      </h5>
                                  </div>
                              </Space>
                          </a>
                      </Dropdown>
                </Space>
            </Space>
          </Header>

          <Layout className="wrap">
              {(mounted && stepForm ==1) && <RenderFormSign />}
              {(mounted && stepForm ==2) && <RenderFormConfirm />}
              {(mounted && stepForm ==3) && <RenderFormResult />}
              {(mounted && stepForm ==4) && <RenderFormResultEmpty />}
          </Layout>
      </Layout>
    </Spin>
  ) : (<></>);
};

export default Page;
