import { useRef, useEffect, useState } from "react";
import {
  Row,
  Col,
  Card,
  Button,
  Form,
  Typography,
  Select,
  Input,
  Table,
  Space,
  Drawer,
  InputNumber,
  Steps,
  Checkbox,
  Layout,
  Spin,
  Avatar,
  Radio,
  Alert,
  Menu,
  Tour,
  Result,
  Dropdown,
  message
} from "antd";
import moment from 'moment'
import { CheckOutlined, GoogleOutlined } from "@ant-design/icons";
import { useParams, useNavigate , Link } from 'react-router-dom'

import { company, prefix } from "./index.const.jsx";

const { Step } = Steps;
const { Title, Text, Paragraph } = Typography;
const { Header, Content, Footer  } = Layout;

const Page = () => {

  const navigate = useNavigate();
  const id = '643dec70ffc480455db4ca8f'


  const [form_company] = Form.useForm();
  const [form_consent] = Form.useForm();

  const [loading, setLoading] = useState(false);
  const [stepForm, setStepForm] = useState(1);


  const [mounted, setMounted] = useState(false);
  const [authData, setAuthData] = useState(false);
  const [formDataCompany, setFormDataCompany] = useState({});

  const [formData, setFormData] = useState({name:'',drive:[]});


  const fetchForm = (callback) => {
      fetch('/api/consent/'+id)
        .then(async response => {
          const data = await response.json();
          console.log('data=====>',data)
          if(!response.ok) {
            const error = (data && data.message) || response.statusText;
            return Promise.reject(error);
          }
          if(data.status == false) {
             setStepForm(5)
             callback()
          } else {
            setFormData(data.data)
            callback()
          }
        }).catch(error => {
          setStepForm(5)
          callback()
        })
  }

  useEffect(() => {
      setMounted(false)
      fetchForm(()=>{
        fetch('/process/auth/check')
          .then(async response => {
            const data = await response.json();
            console.log('data=====>',data)
            if(!response.ok) {
              const error = (data && data.message) || response.statusText;
              return Promise.reject(error);
            }
            if(data.status == false) {
               navigate("/?form=/consent/"+id);
            } else {
              setAuthData(data)
              setMounted(true)
            }
          }).catch(error => {
            console.log('error=>call api',error)
          })
      })   
  },[])


  const onFinishCompany = (values) => {
    message.success('complete step 1')
    setFormDataCompany({...values,company:company.filter(item=>item.value==values.company_name)[0]})
    var found = formData.drive.filter(item=>item.key==company.filter(item=>item.value==values.company_name)[0].key)
    if(found.length==1){
      setStepForm(2)
    }else{
      setStepForm(5)
    }
  }


  const RenderFormCompany = () => {
    return (
      <Form form={form_company} layout="vertical" onFinish={onFinishCompany}>
        <Row gutter={[24, 24]} className="mb-10">
          <Col span={24}>
            <Card>
              <Row gutter={[12, 0]}>
                <Col span={24} align="center">
                  <Title level={5}>
                  ข้อมูลพนักงาน
                  </Title>
                </Col>
                <Col xs={{ span: 24 }} lg={{ span: 18 }}>
                      <Form.Item label="บริษัท" name="company_name" rules={[{required: true,message: ""}]}>
                        <Select placeholder="โปรดเลือก" className="w-100" options={company} />
                      </Form.Item>
                </Col>
                <Col xs={{ span: 24 }} lg={{ span: 6 }}>
                      <Form.Item label="รหัสพนักงาน" name="employee_id" rules={[{required: true,message: ""}]}>
                        <Input className="w-100" />
                      </Form.Item>
                </Col>
                <Col xs={{ span: 24 }} lg={{ span: 4 }}>
                      <Form.Item label="(นาย/นาง/นางสาว)" name="prefix" rules={[{required: true,message: ""}]}>
                        <Select placeholder="โปรดเลือก" className="w-100" options={prefix} />
                      </Form.Item>
                </Col>
                <Col xs={{ span: 24 }} lg={{ span: 10 }}>
                      <Form.Item label="ชื่อ (ภาษาไทย)" name="first_name" rules={[{required: true,message: ""}]}>
                        <Input className="w-100" />
                      </Form.Item>
                </Col>
                <Col xs={{ span: 24 }} lg={{ span: 10 }}>
                      <Form.Item label="นามสกุล (ภาษาไทย)" name="last_name" rules={[{required: true,message: ""}]}>
                        <Input className="w-100" />
                      </Form.Item>
                </Col>
                <Col xs={{ span: 24 }} lg={{ span: 4 }}>
                </Col>
                <Col xs={{ span: 24 }} lg={{ span: 10 }}>
                      <Form.Item label="ชื่อ (ภาษาอังกฤษ)" name="first_name_en" rules={[{required: true,message: ""}]}>
                        <Input className="w-100" />
                      </Form.Item>
                </Col>
                <Col xs={{ span: 24 }} lg={{ span: 10 }}>
                      <Form.Item label="นามสกุล (ภาษาอังกฤษ)" name="last_name_en" rules={[{required: true,message: ""}]}>
                        <Input className="w-100" />
                      </Form.Item>
                </Col>
              </Row>
            </Card>
          </Col>
          <Col span={24} align="right">
            <Button type="primary" htmlType="submit">
              Next
            </Button>
          </Col>
        </Row>
      </Form>
    )
  }

  function onBack() {
      setStepForm(1)
  }

  function onFinishConsent(values) {
        setLoading(true)
        var body = {
         method: 'post',
         headers: {'Content-Type':'application/json'},
         body: JSON.stringify(formDataCompany)
        }
        console.log(body)
        fetch('/api/consent/'+id,body)
          .then(async response => {
            const data = await response.json();
            console.log('data=====>',data)
            if(!response.ok) {
              const error = (data && data.message) || response.statusText;
              return Promise.reject(error);
            }else{
              message.success('Complete')
              setStepForm(4)
              setLoading(false)
            }
          }).catch(error => {
            message.error('กรุณาลองใหม่อีกครั้ง')
            setLoading(false)
            console.log('error=>call api',error)
          })
  }

  const RenderFormConsent = () => {
    return (
    <>
      <Form form={form_consent} className="inline_form" onFinish={onFinishConsent}>
        <Row gutter={[24, 24]} className="mb-10">
          <Col span={24}>
            <Card className="form-law">
            <Row gutter={[24, 48]}>
              <Col span={24}>
                <Title level={5} align="center">
                  ประกาศ
                  <br />
                  บริษัท บิทคับ แคปปิตอลกรุ๊ป โฮลดิ้งส์ จำกัด
                  <br />
                  เรื่อง ข้อตกลงการปฏิบัติงานนอกสำนักงาน (Agreement regarding
                  the work from home)
                </Title>
              </Col>
              <Col span={24} align="right">
                ประกาศ ณ วันที่ 18 เดือน เมษายน พ.ศ. 2566
              </Col>
              <Col span={24}>
                <Typography>
                  <Paragraph className="text-indent">
                    ตามที่บริษัท บิทคับ แคปปิตอลกรุ๊ป โฮลดิ้งส์ จำกัด{" "}
                    <Text strong>(“บริษัท”)</Text> ได้มีนโยบาย Work From Home
                    ตามสถานการณ์แพร่ระบาดของของเชื้อไวรัสโคโรนา 2019 (COVID-19)
                    ได้ขยายวงกว้างอย่างรวดเร็ว
                    ซึ่งส่งผลกระทบต่อการดำรงชีวิตของพนักงานเป็นอย่างมาก
                    บริษัทจึงประกาศให้พนักงานทำงานที่บ้าน (Work From Home)
                    เพื่อความปลอดภัยและสุขภาพอนามัยที่ดีของพนักงาน
                    และความรับผิดชอบต่อส่วนรวมในการป้องกันการระบาดของเชื้อไวรัสโคโรนา
                    2019 (COVID-19) ซึ่งมีผลบังคับใช้ตั้งแต่ 14 มีนาคม 2563
                    จนถึงปัจจุบัน นั้น
                  </Paragraph>
                  <Paragraph className="text-indent">
                    เพื่อให้เกิดความชัดเจนเกี่ยวกับระเบียบการปฏิบัติงานนอกสำนักงานของบริษัท
                    อันเป็นไปเพื่อประโยชน์ต่อการประกอบกิจการของบริษัทและเพื่อเป็นการส่งเสริมคุณภาพชีวิตและการทำงานของพนักงาน
                    บริษัทได้จัดทำข้อตกลงการปฏิบัติงานนอกสำนักงานฉบับนี้ขึ้น
                    โดยกำหนดสิทธิและหน้าที่ระหว่างบริษัทและพนักงาน ดังต่อไปนี้
                  </Paragraph>

                  <Paragraph className="text-indent">
                    1.
                    ข้อตกลงการปฏิบัติงานนอกสำนักงานฉบับนี้ให้มีผลบังคับใช้ตั้งแต่วันที่
                    18 เมษายน 2566 เป็นต้นไป
                    เว้นแต่จะมีการยกเลิกหรือเปลี่ยนแปลงเป็นอย่างอื่น
                  </Paragraph>
                  <Paragraph className="text-indent">
                    2. บริษัทตกลงให้สิทธิพนักงานในการปฏิบัติงานในสถานที่อื่นใด
                    นอกเหนือจากบริเวณสำนักงานของบริษัท
                    หรือสถานที่อื่นใดที่บริษัทกำหนด{" "}
                    <Text strong>(“ปฏิบัติงานนอกสำนักงานของบริษัท”)</Text>{" "}
                    ต่อไปนี้ให้เรียกพนักงานที่มีสิทธิปฏิบัติงานนอกสำนักงานของบริษัทว่า{" "}
                    <Text strong>“พนักงานที่มีสิทธิ”</Text>
                  </Paragraph>
                  <Paragraph className="text-indent">
                    3. พนักงานที่มีสิทธิรับทราบและตกลงว่า
                    พนักงานที่มีสิทธิสามารถปฏิบัติงานนอกสำนักงานของบริษัทได้เป็นเวลา
                    5 วันต่อสัปดาห์หรือตามเวลาอื่นใดที่บริษัทกำหนด
                    <ul>
                      <li>
                        ทั้งนี้ พนักงานที่มีสิทธิรับทราบและตกลงว่า
                        ในการใช้สิทธิปฏิบัติงานนอกสำนักงานของบริษัท
                        พนักงานที่มีสิทธิต้องปฏิบัติงานตามความรับผิดชอบและตามที่ได้รับมอบหมายจากบริษัทอย่างเต็มที่
                        ด้วยความต้ังใจและเต็มกำลังความสามารถ
                        และพนักงานที่มีสิทธิรับรองว่าการใช้สิทธิปฏิบัติงานนอกสำนักงานของบริษัทจะไม่ส่งผลกระทบต่อการประสิทธิภาพในการทำงานตามความรับผิดชอบ
                        และตามที่บริษัทได้มอบหมาย การติดต่อ
                        การประสานงานและการให้ความร่วมมือระหว่างผู้บริหาร
                        หัวหน้างาน เพื่อนพนักงาน รวมถึงบุคคลภายนอกแต่อย่างใด
                        โดยพนักงานที่มีสิทธิจะปฏิบัติงานในระดับและมาตรฐานที่ดีเช่นเดียวกับเวลาที่พนักงานที่มีสิทธิปฏิบัติงานตามปกติในสำนักงานของบริษัท
                        หรือสถานที่อื่นใดที่บริษัทกำหนดทุกประการ
                      </li>
                    </ul>
                  </Paragraph>

                  <Paragraph className="text-indent">
                    4. พนักงานที่มีสิทธิรับทราบและตกลงว่า
                    ในระหว่างที่ใช้สิทธิปฏิบัติงานนอกสำนักงานของบริษัท
                    พนักงานที่มีสิทธิต้องปฏิบัติงานตามหลักเกณฑ์และข้อกำหนดเกี่ยวกับการทำงาน
                    วันและเวลาทำงาน เวลาพักและการทำงานล่วงเวลา
                    ตามที่กำหนดตามสัญญาจ้างแรงงานและข้อบังคับการทำงานของบริษัท
                    <ul>
                      <li>
                        ทั้งนี้
                        ในกรณีที่พนักงานที่มีสิทธิสมัครใจและบริษัทได้อนุมัติ
                        หรือในกรณีที่บริษัทให้พนักงานที่มีสิทธิทำงานล่วงเวลาเนื่องจากสภาพของงานต้องทำติดต่อกันตามเหตุที่ระบุตามกฎหมายให้พนักงานที่มีสิทธิทำงานล่วงเวลา
                        พนักงานที่มีสิทธิรับทราบและตกลงปฏิบัติงานในช่วงการทำงานล่วงเวลาในระดับและมาตรฐานเช่นเดียวกับการทำงานในเวลางานปกติ
                        และตกลงยินยอมให้ผู้บริหาร หัวหน้างาน
                        เพื่อนพนักงานและบุคคลภายนอกที่เกี่ยวข้องกับการทำงานของพนักงานที่มีสิทธิติดต่อสื่อสารและประสานงาน
                        รวมถึงตกลงให้ความร่วมมือต่าง ๆ
                        เพื่อการทำงานที่มีประสิทธิภาพ
                        โดยพนักงานที่มีสิทธิจะไม่ปฏิเสธการติดต่อสื่อสารและประสานงานจากบุคคลดังกล่าวแต่อย่างใด
                      </li>
                    </ul>
                  </Paragraph>

                  <Paragraph className="text-indent">
                    5. พนักงานที่มีสิทธิรับทราบและตกลงว่า
                    การใช้สิทธิปฏิบัติงานนอกสำนักงานของบริษัทเป็นเพียงการใช้สิทธิในการปฏิบัติงานตามความรับผิดชอบและตามที่บริษัทมอบหมายในสถานที่อื่น
                    นอกเหนือจากสำนักงานของบริษัท
                    หรือสถานที่ที่บริษัทกำหนดเท่านั้น
                    โดยวันที่มีการใช้สิทธิปฏิบัติงานนอกสำนักงานของบริษัทเป็นวันทำงานปกติและไม่ใช่วันหยุดหรือวันลาของพนักงานที่มีสิทธิแต่อย่างใด
                    <ul>
                      <li>
                        พนักงานที่มีสิทธิรับทราบและตกลงว่า
                        พนักงานมีสิทธิในการลาหยุดตามหลักเกณฑ์และเงื่อนไขที่กำหนดไว้ในข้อบังคับของบริษัท
                        โดยพนักงานที่มีสิทธิสามารถขออนุมัติการลาหยุดได้
                        โดยยื่นคำขอผ่านผ่านระบบออนไลน์ในระบบ อิเล็กทรอนิกส์
                        หรือวิธีการอื่นตามที่บริษัทกำหนดตามปกติ
                      </li>
                    </ul>
                  </Paragraph>

                  <Paragraph className="text-indent">
                    6.
                    พนักงานที่มีสิทธิยังคงมีขอบเขตหน้าที่และความรับผิดชอบตามที่กำหนดไว้ในสัญญาจ้างและตามที่บริษัทได้มอบหมายทุกประการ
                    โดยการใช้สิทธิปฏิบัติงานนอกสำนักงานของบริษัทไม่ส่งผลกระทบต่อขอบเขตหน้าที่การทำงานของพนักงานที่มีสิทธิแต่อย่างใด
                    ทั้งนี้เพื่อความชัดเจน
                    บริษัทมีสิทธิแต่เพียงผู้เดียวในการกำหนด เปลี่ยนแปลง
                    มอบหมายหรือ ยกเลิกตำแหน่งงาน หรือความรับผิดชอบใด ๆ
                    ตามที่บริษัทเห็นสมควรได้ตามปกติ
                    โดยบริษัทอาจแจ้งให้ทราบทางระบบ
                    อิเล็กทรอนิกส์หรือวิธีการอื่นตามที่บริษัทเห็นสมควร
                  </Paragraph>

                  <Paragraph className="text-indent">
                    7. ในระหว่างที่มีการใช้สิทธิปฏิบัติงานนอกสำนักงานของบริษัท
                    พนักงานที่มีสิทธิต้องปฏิบัติตามสัญญาจ้างแรงงานระหว่างพนักงานที่มีสิทธิและบริษัท
                    ข้อบังคับการทำงาน กฎ ระเบียบ
                    คุณธรรมและข้อพึงปฏิบัติในการทำงาน (Code of conduct) ข้อกำหนด
                    เงื่อนไขและนโยบายใด ๆ
                    ของบริษัทตามปกติด้วยความสุจริตและโดยเคร่งครัด
                    เว้นแต่สภาพการปฏิบัติงานนอกสำนักงานไม่สามารถเปิดช่องให้ดำเนินการเช่นนั้นได้
                    <ul>
                      <li>
                        ทั้งนี้
                        ในกรณีที่พนักงานที่มีสิทธิมีข้อสงสัยประการใดเกี่ยวกับการใช้สิทธิปฏิบัติงานนอกสำนักงานของบริษัท
                        พนักงานที่มีสิทธิต้องติดต่อสอบถามทีมทรัพยากรบุคคลเพื่อตรวจสอบการปฏิบัติงานที่ถูกต้องต่อไป
                      </li>
                    </ul>
                  </Paragraph>

                  <Paragraph className="text-indent">
                    8. ในระหว่างที่มีการใช้สิทธิปฏิบัติงานนอกสำนักงานของบริษัท
                    พนักงานที่มีสิทธิตกลงยินยอมดำเนินการ ดังต่อไปนี้
                    <ul>
                      <li>
                        (ก) บริษัทมีสิทธิประเมินความประพฤติ
                        การทำงานและทัศนคติของพนักงานที่มีสิทธิตามที่บริษัทเห็นสมควร
                        โดยบริษัทมีสิทธินำผลการประเมินดังกล่าวมาพิจารณาการประเมินผลการทำงานของพนักงานที่มีสิทธิตามหลักเกณฑ์และเงื่อนไขที่บริษัทกำหนด
                      </li>
                      <li>
                        (ข)
                        พนักงานที่มีสิทธิต้องสื่อสารผ่านเทคโนโลยีสารสนเทศที่บริษัทก
                        าหนดกับบริษัทตลอดเวลาที่ ปฏิบตัิงานนอกส านักงานของบริษทั
                        เพื่อการสื่อสารที่เป็นประสิทธิภาพและปราศจากอุปสรรคในการปฏิบตัิงาน
                        พร้อมท้งั เพื่อให้การปฏิบตัิงานนอกสา นกังานของบริษทั
                        เป็นไปดว้ยความมนั่ คงปลอดภย
                      </li>
                      <li>
                        (ค)
                        พนักงานที่มีสิทธิตกลงจะจัดสรรพ้ืนที่ทำงานให้เป็นสัดส่วนและมิดชิด
                        เพื่อป้องกันการรั่วไหล
                        หรือการล่วงรู้ข้อมูลของบริษัทโดยบุคคลภายนอกที่ไม่เกี่ยวข้องกับบริษัท
                        และต้องปฏิบัติตามระเบียบ ข้อบังคับ
                        ข้อปฏิบัติและนโยบายของบริษัทเกี่ยวกับเทคโนโลยีสารสนเทศ
                        (Information Technology : IT)
                        เทคโนโลยีสารสนเทศด้านความมั่นคงและความปลอดภัย (IT
                        Security) และการคุ้มครองข้อมูลส่วนบุคคล (Personal data
                        protection) และกฎระเบียบอื่นใดที่เกี่ยวข้องของ
                        บริษัทตลอดเวลาที่ใช้สิทธิปฏิบัติงานนอกสำนักงานของบริษัทอย่างเคร่งครัด
                      </li>
                      <li>
                        (ง)
                        พนักงานที่มีสิทธิต้องลงเวลาปฏิบัติงานในระบบสารสนเทศของฝ่ายทรัพยากรบุคคลผ่านระบบออนไลน์
                        ซึ่งระบบได้จัดหาไว้ให้หรือตามวิธีการใด ๆ ที่บริษัทกำหนด
                        ทั้งนี้ข้อตกลงข้อนี้บังคับใช้แต่เฉพาะพนักงานที่มีสิทธิที่บริษัทกำหนดให้ต้องลงเวลาปฏิบัติงานเท่านั้น
                      </li>
                      <li>
                        (จ)
                        พนักงานที่มีสิทธิต้องปฏิบัติงานตามแผนงานหรือคำสั่งที่ได้รับมอบหมายจากบริษัท
                        ผู้บริหารหรือหัวหน้างานกำหนด
                        และรายงานผลการดำเนินการดังกล่าวต่อผู้ที่มีอำนาจและเกี่ยวข้อง
                      </li>
                      <li>
                        (ฉ)
                        พนักงานที่มีสิทธิจะต้องปฏิบัติงานด้วยความระมัดระวังและดำเนินการใด
                        ๆ ตามจำเป็นสมควร
                        เพื่อป้องกันความเสียหายแก่ทรัพย์สินของบริษัท
                        ซึ่งอยู่ในความครอบครองของพนักงานที่มีสิทธิ
                      </li>
                      <li>
                        ทั้งนี้ ในกรณีที่พนักงานที่มีสิทธิกระทำการใด ๆ
                        อันก่อให้เกิดความเสียหายหรือสูญหายต่อทรัพย์สินของบริษํท
                        พนักงานที่มีสิทธิตกลงชดใช้ค่าเสียหายและจ่ายดอกเบ้ียกรณีผิดนัดจากบรรดาความเสียหายที่เกิดขึ้นแก่บริษัทตามที่บริษัทกำหนด
                      </li>
                    </ul>
                  </Paragraph>

                  <Paragraph className="text-indent">
                    9. ในระหว่างที่มีการใช้สิทธิปฏิบัติงานนอกสำนักงานของบริษัท
                    บริษัทตกลงอำนวยความสะดวกให้แก่พนักงานที่มีสิทธิดังต่อไปนี้
                    <ul>
                      <li>
                        (ก) บริษัทตกลงจัดหาเครื่องมือ คอมพิวเตอร์
                        โทรศัพท์และอุปกรณ์การทำงานที่จำเป็นต่อการปฏิบัติงานนอกสำนักงานของบริษัทตามที่บริษัทกำหนด
                      </li>
                      <li>
                        (ข)
                        บริษัทตกลงจัดหาเครื่องมือหรือซอฟแวร์ที่ใช้ในการเข้าถึงไฟล์งาน
                        หรือเอกสารบนเครือข่ายคอมพิวเตอร์
                        รวมถึงอุปกรณ์เครื่องมือในรักษาความมั่นคงปลอดภัยไซเบอร์ตามที่บริษัทกำหนด
                      </li>
                      <li>
                        (ค)
                        บริษัทตกลงจัดหาบริการในการส่งพัสดุหรือเอกสารเพื่อประโยชน์ในการปฏิบัติงานนอกสำนักงานตามที่บริษัทกำหนด
                      </li>
                    </ul>
                  </Paragraph>

                  <Paragraph className="text-indent">
                    10.
                    พนักงานที่มีสิทธิตกลงไม่ใช้สิทธิปฏิบัติงานนอกสำนักงานของบริษัท
                    โดยการปฏิบัติงานในต่างประเทศ
                    เว้นแต่จะได้รับการอนุมัติล่วงหน้าเป็นลายลักษณ์อักษรจากบริษัท
                  </Paragraph>

                  <Paragraph className="text-indent">
                    11.
                    เพื่อความชัดเจนเกี่ยวกับรายละเอียดการปฏิบัติงานนอกสำนักงานของบริษัท
                    พนักงานตกลงให้บริษัทเป็นผู้มีสิทธิโดยชอบแต่เพียงผู้เดียวในการกำหนดข้อกำหนด
                    หลักเกณฑ์และเงื่อนไขเกี่ยวกับการใช้สิทธิปฏิบัติงานนอกสำนักงานของบริษัทเพิ่มเติมภายใต้กรอบของกฎหมาย{" "}
                    <Text strong>
                      (“นโยบายการปฏิบัติงานนอกสำนักงานของบริษัท”)
                    </Text>{" "}
                    โดยพนักงานตกลงปฏิบัติตามนโยบายการปฏิบัติงานนอกสำนักงานของบริษัทอย่างเคร่งครัด
                    นอกจากน้ีพนักงานตกลงให้สิทธิบริษัทในการกำหนด เปลี่ยนแปลง
                    แก้ไขหรือยกเลิกนโยบายการปฏิบัติงานนอกสำนักงานของบริษัทตามที่บริษัทเห็นสมควร
                    โดยบริษัทต้องให้พนักงานทุกท่านทราบล่วงหน้าเป็นลายลักษณ์อักษรต่อไป
                  </Paragraph>

                  <Paragraph className="text-indent">
                    12.
                    ให้ข้อตกลงการปฏิบัติงานนอกสำนักงานเป็นส่วนหนึ่งของสัญญาจ้างแรงงานระหว่างพนักงานกับบริษัท
                  </Paragraph>

                  <Paragraph className="text-indent">
                    พนักงานได้อ่านและเข้าใจข้อตกลงข้างต้นทั้งหมดดีแล้ว
                    จึงได้ลงลายมือชื่อไว้เป็นหลักฐาน
                  </Paragraph>
                </Typography>
              </Col>
              <Col span={12} align="center">
                <Space direction="vertical" size="middle">
                  <Text>
                    ลงชื่อ....ทวีทรัพย์ ราวรรณ.......
                  </Text>
                  <Text>( นายทวีทรัพย์ ราวรรณ )</Text>
                  <Text>กรรมการบริษัท</Text>
                </Space>
              </Col>
              <Col span={12} align="center">
                <Space direction="vertical" size="middle">
                  <Text>
                    ลงชื่อ....สุกฤษฏิ์ พุทธวิริยะ.......
                  </Text>
                  <Text>( นายสุกฤษฏิ์ พุทธวิริยะ )</Text>
                  <Text>กรรมการบริษัท</Text>
                </Space>
              </Col>

              <Col span={12}></Col>

              <Col span={12} align="center">
                <Space direction="vertical" size="middle">
                  <Text>
                    ลงชื่อ.....{formDataCompany.first_name_en}..{formDataCompany.last_name_en}...พนักงาน
                  </Text>
                  <Text>( {formDataCompany.prefix} {formDataCompany.first_name} {formDataCompany.last_name} )</Text>
                  <Text>
                    วันที่ {moment().format('YYYY-MM-DD')}
                  </Text>
                </Space>
              </Col>
            </Row>
          </Card>
          </Col>
          <Col span={24} align="right">
      
              <Button onClick={()=>{
                onBack()
              }} className="mr-5">
                Back
              </Button>
              <Button type="primary" htmlType="submit">
                Sign and Submit
              </Button>
     
          </Col>
        </Row>
      </Form>

    </>
    )
  }

  const RenderFormResult = () => {
    return (
       <Result
          status="success"
          title="ทำการส่งแบบฟอร์มสำเร็จ"
          extra={[
            <a href='/process/auth/logout/'><Button type="primary">
              Done and Logout
            </Button></a>
          ]}
        />
      )
  }

  const RenderFormResultEmpty = () => {
    return (
       <Result
          title="ไม่พบแบบแฟอร์ม"
          extra={[
            <a href='/process/auth/logout/'><Button type="primary">
              Done and Logout
            </Button></a>
          ]}
        />
      )
  }

  return mounted ? (
    <Spin spinning={loading} delay={500}>
      <Layout>
          <Header className="w-100" theme={'light'}>
            <Space className="w-100 justify-space-between">

                <Space size="middle"  align="center">
                  <a className="App-brand"><img src={'/logo.png'} style={{ height: 39 }}/></a>
                </Space>
                <Space size="middle"  align="center">
                      <Dropdown overlay={(
                <Menu>
                    <Menu.Item key="1">
                        <a href='/process/auth/logout/'>Logout</a>
                    </Menu.Item>
                </Menu>
            )} trigger={["click"]}>
                          <a className="ant-dropdown-link" onClick={(e) => e.preventDefault()}>
                              <Space>
                                  <Avatar src={authData.user.avatar} className="mr-2" />
                                  <div className="mr-2">
                                      <h5 className="mb-0">
                                          {authData.user.name}
                                      </h5>
                                  </div>
                              </Space>
                          </a>
                      </Dropdown>
                </Space>
            </Space>
          </Header>

          <Layout className="wrap">
              {(mounted && stepForm ==1) && <RenderFormCompany />}
              {(mounted && stepForm ==2) && <RenderFormConsent />}
              {(mounted && stepForm ==4) && <RenderFormResult />}
              {(mounted && stepForm ==5) && <RenderFormResultEmpty />}
          </Layout>
      </Layout>
    </Spin>
  ) : (<></>);
};

export default Page;
