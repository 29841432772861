import { useRef, useEffect, useState } from "react";
import {
  Row,
  Col,
  Card,
  Button,
  Form,
  Typography,
  Select,
  Input,
  Table,
  Space,
  Drawer,
  InputNumber,
  Steps,
  Checkbox,
  Layout,
  Spin,
  Avatar,
  Radio,
  Alert,
  Menu,
  Tour,
  Result,
  Dropdown,
  Modal,
  message
} from "antd";
import moment from 'moment'
import { CheckOutlined, GoogleOutlined, ExclamationCircleOutlined } from "@ant-design/icons";
import { useParams, useNavigate , Link } from 'react-router-dom'

import { company, prefix } from "./index.const.jsx";

const { confirm } = Modal;
const { Step } = Steps;
const { Title, Text, Paragraph } = Typography;
const { Header, Content, Footer  } = Layout;

const Page = () => {

  const navigate = useNavigate();
  const id = '643dec4affc480455db4c7f3';

  const [form_read] = Form.useForm();
  const [form_company] = Form.useForm();
  const [form_consent] = Form.useForm();

  const [loading, setLoading] = useState(false);
  const [stepForm, setStepForm] = useState(1);


  const [mounted, setMounted] = useState(false);
  const [authData, setAuthData] = useState(false);
  const [checked, setChecked] = useState(false);
  const [companyName, setCompanyName] = useState('');
  const [formDataCompany, setFormDataCompany] = useState({});

  const [formData, setFormData] = useState({name:'',drive:[]});



  const company_index = {
        "BA":"BA",
        "BC":"BCGH",
        "BO":"BO",
        "BM":"BM",
        "BV":"BV",
        "BI":"BI",
        "BW":"BWT",
        "BP":"BP",
        "BB":"BBT",
    }


  const fetchForm = (callback) => {
      fetch('/api/consent/'+id)
        .then(async response => {
          const data = await response.json();
          console.log('data=====>',data)
          if(!response.ok) {
            const error = (data && data.message) || response.statusText;
            return Promise.reject(error);
          }
          if(data.status == false) {
             setStepForm(5)
             callback()
          } else {
            setFormData(data.data)
            callback()
          }
        }).catch(error => {
          setStepForm(5)
          callback()
        })
  }

  useEffect(() => {
      setMounted(false)
      fetchForm(()=>{
        fetch('/process/auth/check')
          .then(async response => {
            const data = await response.json();
            console.log('data=====>',data)
            if(!response.ok) {
              const error = (data && data.message) || response.statusText;
              return Promise.reject(error);
            }
            if(data.status == false) {
               navigate("/?form=/consent/"+id);
            } else {

              setCompanyName(company.filter(item=>item.key==company_index[data.user.emp_id.slice(0,2)])[0].value)

              setAuthData(data)
              setMounted(true)
            }
          }).catch(error => {
            console.log('error=>call api',error)
          })
      })
   
  },[])



  const onFinishRead = (values) => {
    if(checked==true){
      setStepForm(2)
    }
  }

  const onChange = (e) => {
    console.log(`checked = ${e.target.checked}`);
    setChecked(e.target.checked)
  };

  const RenderFormRead = () => {
    return (
    <>
      <Form form={form_read} className="inline_form" onFinish={onFinishRead}>
        <Row gutter={[24, 24]} className="mb-10">
          <Col span={24}>
             <Card className="form-law">
            <Row gutter={[24, 48]}>
              <Col span={24}>
                <Title level={5} align="center">
                  ประกาศ
                  <br />
                  บริษัท {companyName} จำกัด
                </Title>
              </Col>
              <Col span={24} align="right">
                ประกาศ ณ วันที่ 18 เดือน เมษายน พ.ศ. 2566
              </Col>
              <Col span={24}>
                <Typography>
                  <Paragraph className="text-indent">
                    เนื่องด้วยบริษัท {companyName} จำกัด{" "}
                    <Text strong>(“บริษัท”)</Text>{" "}
                    เล็งเห็นถึงความสำคัญของสิทธิความเป็นส่วนตัว
                    และคุณภาพชีวิตของพนักงานทุกท่าน ดังน้ัน
                    บริษัทจึงขอเรียนแจ้งว่า
                    บริษัทมีความเข้าใจเป็นอย่างดีหากหลังสิ้นสุดระยะเวลาทำงานตามที่กำหนดในสัญญาจ้างแรงงานตามข้อบังคับของบริษัท
                    หรือตามที่บริษัทกำหนดซึ่งรวมถึงหลังสิ้นสุดระยะเวลาทำงานล่วงเวลา
                    เวลาทำงานในวันหยุดและเวลาทำงานล่วงเวลาในวันหยุด{" "}
                    <Text strong>(“ระยะเวลาทำงาน”)</Text>{" "}
                    พนักงานประสงค์จะปฏิเสธการติดต่อสื่อสารกับนายจ้าง หัวหน้างาน
                    ผู้ควบคุมงาน หรือผู้ตรวจงาน อันเป็นสิทธิที่พึงมีตามกฎหมาย
                  </Paragraph>
                  <Paragraph className="text-indent">
                    อย่างไรก็ตามเนื่องด้วยการประกอบธุรกิจของบริษัทที่มีความจำเป็นต้องสร้างการเติบโตและความสามารถในการแข่งขันอย่างต่อเนื่อง
                    เพื่อประโยชน์ของผู้มีส่วนได้เสียทุกฝ่าย ไม่ว่าจะเป็นบริษัท
                    ลูกค้าหรือพนักงาน
                    ซึ่งล้วนมีส่วนได้รับประโยชน์จากการเจริญเติบโตและความแข็งแกร่งของบริษัท
                  </Paragraph>
                  <Paragraph className="text-indent">
                    บริษัทจึงขอให้พนักงานโปรดให้ความร่วมมือในการให้ความยินยอมให้บริษัท
                    หัวหน้างาน ผู้บังคับบัญชา ผู้ควบคุมงาน ผู้ตรวจงาน
                    รวมถึงเพื่อนพนักงานและลูกค้าผู้ต้องการการสนับสนุนและความช่วยเหลือสามารถติดต่อพนักงานได้แม้จะเป็นในช่วงเวลาภายหลังสิ้นสุด
                    ระยะเวลาทำงาน เฉพาะแต่ในกรณีดังต่อไปนี้
                    <ul>
                      <li>
                        1. ยินยอมให้บริษัท นายจ้าง หัวหน้างาน ผู้ควบคุมงาน
                        ผู้บังคับบัญชา ผู้ตรวจงาน หรือตัวแทนของบุคคลดังกล่าว
                        พนักงานหรือลูกค้า ของบริษัท หรือคู่ค้า หรือผู้แทนจำหน่าย
                        หรือซัพพลายเออร์ หรือผู้รับจ้างผลิต หรือที่ปรึกษา
                        หรือบุคคลที่เกี่ยวข้องและจำเป็นต่อการประกอบธุรกิจของบริษัท
                        (“ผู้ที่เกี่ยวข้อง”) ติดต่อสื่อสาร
                        ประสานงานหรือขอความช่วยเหลือ ไม่ว่าด้วยวิธีการใดก็ตาม
                        ในเรื่องที่เกี่ยวข้องกับการปฏิบัติงาน
                        หรืออยู่ในความรับรู้ของพนักงานตามความจำเป็นของบริษัทภายหลังจากสิ้นสุดระยะเวลาทำงานได้
                      </li>
                      <li>
                        2. ตกลงไม่ตัดขาดการติดต่อสื่อสารหรือปิดอุปกรณ์สื่อสาร
                        เครื่องมือสื่อสาร
                        และต้องให้ผู้ที่เกี่ยวข้องติดต่อประสานงาน
                        หรือขอความร่วมมือได้ในกรณีที่ผู้เกี่ยวข้องมีความจำเป็นความเร่งด่วนหรือเหตุฉุกเฉิน
                      </li>
                    </ul>
                  </Paragraph>

                  <Paragraph className="text-indent">
                    ความตกลงยินยอมข้างต้นมีผลบังคับใช้กับพนักงานที่ปฏิบัติงานที่สำนักงานของบริษัท
                    สถานที่อื่นใดที่บริษัทกำหนด
                    หรือสถานที่อื่นใดที่พนักงานเลือกในการปฏิบัติงานตั้งแต่วันที่
                    18 เมษายน 2566 เป็นต้นไป
                  </Paragraph>
                  <Paragraph className="text-indent">
                    พนักงานได้อ่าน
                    และเข้าใจข้อกำหนดและขอบเขตของสิทธิข้างต้นทั้งหมดดีแล้ว
                    จึงได้ให้ความยินยอมและลงลายมือชื่อไว้เป็นหลักฐาน
                  </Paragraph>
                </Typography>
              </Col>
              <Col span={12}></Col>

              <Col span={12} align="center">
                <Space direction="vertical" size="middle">
                  <Text>
                    ลงชื่อ...........................................................พนักงาน
                  </Text>
                  <Text>(__________________________)</Text>
                  <Text>
                    วันที่ ..................................................
                  </Text>
                </Space>
              </Col>
            </Row>
          </Card>
          </Col>
          <Col span={24} align="left">
              
                <Checkbox onChange={onChange}>ข้าพเจ้าได้อ่าน และเข้าใจข้อกำหนดและขอบเขตของสิทธิข้างต้นทั้งหมดดีแล้ว</Checkbox>
           
          </Col>
          <Col span={24} align="left">
      
              <Button type="primary" htmlType="submit" disabled={!checked}>
                ตกลงและเข้าสู่ขั้นตอน sign เอกสาร
              </Button>
     
          </Col>
        </Row>
      </Form>

    </>
    )
  }


  const onFinishCompany = (values) => {
    
    values.company_name = company.filter(item=>item.key==company_index[authData.user.emp_id.slice(0,2)])[0].value
    values.employee_id = authData.user.emp_id
    setFormDataCompany({...values,company:company.filter(item=>item.value==values.company_name)[0]})
    var found = formData.drive.filter(item=>item.key==company.filter(item=>item.value==values.company_name)[0].key)
    if(found.length==1){
      message.success('Create Electronic signature complete')
      setStepForm(3)
    }else{
      setStepForm(5)
    }
  }


  const RenderFormSign = () => {
    return (
      <Form form={form_company} layout="vertical" onFinish={onFinishCompany}>
        <Row gutter={[24, 24]} className="mb-10">
          <Col span={24}>
            <Card>
              <Row gutter={[12, 0]}>
                <Col span={24} align="center">
                  <Title level={5}>
                  กรอกข้อมูลพนักงาน เพื่อ Sign เอกสาร
                  </Title>
                </Col>
                <Col xs={{ span: 24 }} lg={{ span: 4 }}>
                      <Form.Item label="(นาย/นาง/นางสาว)" name="prefix" rules={[{required: true,message: ""}]}>
                        <Select placeholder="โปรดเลือก" className="w-100" options={prefix} />
                      </Form.Item>
                </Col>
                <Col xs={{ span: 24 }} lg={{ span: 10 }}>
                      <Form.Item label="ชื่อ (ภาษาไทย)" name="first_name" rules={[{required: true,message: ""}]}>
                        <Input className="w-100" />
                      </Form.Item>
                </Col>
                <Col xs={{ span: 24 }} lg={{ span: 10 }}>
                      <Form.Item label="นามสกุล (ภาษาไทย)" name="last_name" rules={[{required: true,message: ""}]}>
                        <Input className="w-100" />
                      </Form.Item>
                </Col>
                <Col xs={{ span: 24 }} lg={{ span: 4 }}>
                </Col>
                <Col xs={{ span: 24 }} lg={{ span: 10 }}>
                      <Form.Item label="First Name (EN)" name="first_name_en" rules={[{required: true,message: ""}]}>
                        <Input className="w-100" />
                      </Form.Item>
                </Col>
                <Col xs={{ span: 24 }} lg={{ span: 10 }}>
                      <Form.Item label="Last Name (EN)" name="last_name_en" rules={[{required: true,message: ""}]}>
                        <Input className="w-100" />
                      </Form.Item>
                </Col>
              </Row>
            </Card>
          </Col>
          <Col span={24} align="left">
            <Button type="primary" htmlType="submit">
              ยืนยันการลงลายมือชื่อผ่านระบบอิเล็กทรอนิกส์
            </Button>
          </Col>
        </Row>
      </Form>
    )
  }





  function onFinishConsent(values) {

    confirm({
      icon: <ExclamationCircleOutlined />,
      title:"Confirm ?",
      content: 'ยืนยันการลงลายมือชื่อการติดต่อสื่อสารหลังสิ้นสุดระยะเวลาทำงาน?',
      onOk() {
        setLoading(true)
        var body = {
         method: 'post',
         headers: {'Content-Type':'application/json'},
         body: JSON.stringify(formDataCompany)
        }
        console.log(body)
        fetch('/api/consent/'+id,body)
          .then(async response => {
            const data = await response.json();
            console.log('data=====>',data)
            if(!response.ok) {
              const error = (data && data.message) || response.statusText;
              return Promise.reject(error);
            }else{
              message.success('Complete')
              setStepForm(4)
              setLoading(false)
            }
          }).catch(error => {
            message.error('กรุณาลองใหม่อีกครั้ง')
            setLoading(false)
            console.log('error=>call api',error)
          })
      },
      onCancel() {
        console.log('Cancel');
      },
    });

  }

  function onBack() {
      setStepForm(2)
  }

  const RenderFormConsent = () => {
    return (
    <>
      <Form form={form_consent} className="inline_form" onFinish={onFinishConsent}>
        <Row gutter={[24, 24]} className="mb-10">
          <Col span={24}>
             <Card className="form-law">
            <Row gutter={[24, 48]}>
              <Col span={24}>
                <Title level={5} align="center">
                  ประกาศ
                  <br />
                  บริษัท {formDataCompany.company.value} จำกัด
                </Title>
              </Col>
              <Col span={24} align="right">
                ประกาศ ณ วันที่ 18 เดือน เมษายน พ.ศ. 2566
              </Col>
              <Col span={24}>
                <Typography>
                  <Paragraph className="text-indent">
                    เนื่องด้วยบริษัท {formDataCompany.company.value} จำกัด{" "}
                    <Text strong>(“บริษัท”)</Text>{" "}
                    เล็งเห็นถึงความสำคัญของสิทธิความเป็นส่วนตัว
                    และคุณภาพชีวิตของพนักงานทุกท่าน ดังน้ัน
                    บริษัทจึงขอเรียนแจ้งว่า
                    บริษัทมีความเข้าใจเป็นอย่างดีหากหลังสิ้นสุดระยะเวลาทำงานตามที่กำหนดในสัญญาจ้างแรงงานตามข้อบังคับของบริษัท
                    หรือตามที่บริษัทกำหนดซึ่งรวมถึงหลังสิ้นสุดระยะเวลาทำงานล่วงเวลา
                    เวลาทำงานในวันหยุดและเวลาทำงานล่วงเวลาในวันหยุด{" "}
                    <Text strong>(“ระยะเวลาทำงาน”)</Text>{" "}
                    พนักงานประสงค์จะปฏิเสธการติดต่อสื่อสารกับนายจ้าง หัวหน้างาน
                    ผู้ควบคุมงาน หรือผู้ตรวจงาน อันเป็นสิทธิที่พึงมีตามกฎหมาย
                  </Paragraph>
                  <Paragraph className="text-indent">
                    อย่างไรก็ตามเนื่องด้วยการประกอบธุรกิจของบริษัทที่มีความจำเป็นต้องสร้างการเติบโตและความสามารถในการแข่งขันอย่างต่อเนื่อง
                    เพื่อประโยชน์ของผู้มีส่วนได้เสียทุกฝ่าย ไม่ว่าจะเป็นบริษัท
                    ลูกค้าหรือพนักงาน
                    ซึ่งล้วนมีส่วนได้รับประโยชน์จากการเจริญเติบโตและความแข็งแกร่งของบริษัท
                  </Paragraph>
                  <Paragraph className="text-indent">
                    บริษัทจึงขอให้พนักงานโปรดให้ความร่วมมือในการให้ความยินยอมให้บริษัท
                    หัวหน้างาน ผู้บังคับบัญชา ผู้ควบคุมงาน ผู้ตรวจงาน
                    รวมถึงเพื่อนพนักงานและลูกค้าผู้ต้องการการสนับสนุนและความช่วยเหลือสามารถติดต่อพนักงานได้แม้จะเป็นในช่วงเวลาภายหลังสิ้นสุด
                    ระยะเวลาทำงาน เฉพาะแต่ในกรณีดังต่อไปนี้
                    <ul>
                      <li>
                        1. ยินยอมให้บริษัท นายจ้าง หัวหน้างาน ผู้ควบคุมงาน
                        ผู้บังคับบัญชา ผู้ตรวจงาน หรือตัวแทนของบุคคลดังกล่าว
                        พนักงานหรือลูกค้า ของบริษัท หรือคู่ค้า หรือผู้แทนจำหน่าย
                        หรือซัพพลายเออร์ หรือผู้รับจ้างผลิต หรือที่ปรึกษา
                        หรือบุคคลที่เกี่ยวข้องและจำเป็นต่อการประกอบธุรกิจของบริษัท
                        (“ผู้ที่เกี่ยวข้อง”) ติดต่อสื่อสาร
                        ประสานงานหรือขอความช่วยเหลือ ไม่ว่าด้วยวิธีการใดก็ตาม
                        ในเรื่องที่เกี่ยวข้องกับการปฏิบัติงาน
                        หรืออยู่ในความรับรู้ของพนักงานตามความจำเป็นของบริษัทภายหลังจากสิ้นสุดระยะเวลาทำงานได้
                      </li>
                      <li>
                        2. ตกลงไม่ตัดขาดการติดต่อสื่อสารหรือปิดอุปกรณ์สื่อสาร
                        เครื่องมือสื่อสาร
                        และต้องให้ผู้ที่เกี่ยวข้องติดต่อประสานงาน
                        หรือขอความร่วมมือได้ในกรณีที่ผู้เกี่ยวข้องมีความจำเป็นความเร่งด่วนหรือเหตุฉุกเฉิน
                      </li>
                    </ul>
                  </Paragraph>

                  <Paragraph className="text-indent">
                    ความตกลงยินยอมข้างต้นมีผลบังคับใช้กับพนักงานที่ปฏิบัติงานที่สำนักงานของบริษัท
                    สถานที่อื่นใดที่บริษัทกำหนด
                    หรือสถานที่อื่นใดที่พนักงานเลือกในการปฏิบัติงานตั้งแต่วันที่
                    18 เมษายน 2566 เป็นต้นไป
                  </Paragraph>
                  <Paragraph className="text-indent">
                    พนักงานได้อ่าน
                    และเข้าใจข้อกำหนดและขอบเขตของสิทธิข้างต้นทั้งหมดดีแล้ว
                    จึงได้ให้ความยินยอมและลงลายมือชื่อไว้เป็นหลักฐาน
                  </Paragraph>
                </Typography>
              </Col>
              <Col span={12}></Col>

              <Col span={12} align="center">
                <Space direction="vertical" size="middle">
                  <Text>
                    ลงชื่อ...{formDataCompany.first_name_en}..{formDataCompany.last_name_en}....พนักงาน
                  </Text>
                  <Text>( {formDataCompany.prefix} {formDataCompany.first_name} {formDataCompany.last_name} )</Text>
                  <Text>
                    วันที่ {moment().format('YYYY-MM-DD')}
                  </Text>
                </Space>
              </Col>
            </Row>
          </Card>
          </Col>
          <Col span={24} align="left">
      
              <Button onClick={()=>{
                onBack()
              }} className="mr-5">
                Back
              </Button>
              <Button type="primary" htmlType="submit">
                ยืนยันลงลายมือชื่อเข้าระบบ
              </Button>
     
          </Col>
        </Row>
      </Form>

    </>
    )
  }




  const RenderFormResult = () => {
    return (
       <Result
          status="success"
          title="ทำการส่งแบบฟอร์มสำเร็จ"
          extra={[
            <a href='/process/auth/logout/'><Button type="primary">
              Done and Logout
            </Button></a>
          ]}
        />
      )
  }


  const RenderFormResultEmpty = () => {
    return (
       <Result
          title="ไม่พบแบบแฟอร์ม"
          extra={[
            <a href='/process/auth/logout/'><Button type="primary">
              Done and Logout
            </Button></a>
          ]}
        />
      )
  }



  return mounted ? (
    <Spin spinning={loading} delay={500}>
      <Layout>
          <Header className="w-100" theme={'light'}>
            <Space className="w-100 justify-space-between">

                <Space size="middle"  align="center">
                  <a className="App-brand"><img src={'/logo.png'} style={{ height: 39 }}/></a>
                </Space>
                <Space size="middle"  align="center">
                      <Dropdown overlay={(
                <Menu>
                    <Menu.Item key="1">
                        <a href='/process/auth/logout/'>Logout</a>
                    </Menu.Item>
                </Menu>
            )} trigger={["click"]}>
                          <a className="ant-dropdown-link" onClick={(e) => e.preventDefault()}>
                              <Space>
                                  <Avatar src={authData.user.avatar} className="mr-2" />
                                  <div className="mr-2">
                                      <h5 className="mb-0">
                                          {authData.user.name}
                                      </h5>
                                  </div>
                              </Space>
                          </a>
                      </Dropdown>
                </Space>
            </Space>
          </Header>

          <Layout className="wrap">
              {(mounted && stepForm ==1) && <RenderFormRead />}
              {(mounted && stepForm ==2) && <RenderFormSign />}
              {(mounted && stepForm ==3) && <RenderFormConsent />}
              {(mounted && stepForm ==4) && <RenderFormResult />}
              {(mounted && stepForm ==5) && <RenderFormResultEmpty />}
          </Layout>
      </Layout>
    </Spin>
  ) : (<></>);
};

export default Page;
