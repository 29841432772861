import { Typography} from "antd";
const { Title, Text } = Typography;


export const company = [
  {key:'BCGH',value:"บิทคับ แคปปิตอล กรุ๊ป โฮลดิ้งส์",label:"BCGH : บริษัท บิทคับ แคปปิตอล กรุ๊ป โฮลดิ้งส์ จำกัด",label_en:"BITKUB CAPITAL GROUP HOLDINGS CO., LTD."},
  {key:'BO',value:"บิทคับ ออนไลน์",label:"BO : บริษัท บิทคับ ออนไลน์ จำกัด",label_en:"BITKUB ONLINE CO., LTD."},
  {key:'BBT',value:"บิทคับ บล็อคเชน เทคโนโลยี",label:"BBT : บริษัท บิทคับ บล็อคเชน เทคโนโลยี จำกัด",label_en:"BITKUB BLOCKCHAIN TECHNOLOGY CO., LTD."},
  {key:'BA',value:"บิทคับ แล็บส์",label:"BA : บริษัท บิทคับ แล็บส์ จำกัด",label_en:"BITKUB LABS CO., LTD."},
  {key:'BV',value:"บิทคับ เวนเจอร์ส",label:"BV : บริษัท บิทคับ เวนเจอร์ส จำกัด",label_en:"BITKUB VENTURES CO., LTD."},
  {key:'BI',value:"บิทคับ อินฟินิตี",label:"BI : บริษัท บิทคับ อินฟินิตี จำกัด",label_en:"BITKUB INFINITY CO., LTD."},
  {key:'BM',value:"บิทคับ เอ็ม",label:"BM : บริษัท บิทคับ เอ็ม จำกัด",label_en:"BITKUB M CO., LTD."},
  {key:'BWT',value:"บิทคับ เวิลด์เทค",label:"BWT : บริษัท บิทคับ เวิลด์เทค จำกัด",label_en:"BITKUB WORLDTECH CO., LTD."},
  {key:'BP',value:"บิทคับ พอร์ทอล",label:"BP : บริษัท บิทคับ พอร์ทอล จำกัด",label_en:"BITKUB PORTAL CO., LTD."}

]

export const prefix = [
  {
    value: "นาย",
    label: "นาย",
  },
  {
    value: "นาง",
    label: "นาง",
  },
  {
    value: "นางสาว",
    label: "นางสาว",
  },
]
