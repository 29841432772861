import { useRef, useEffect, useState } from "react";
import {
  Row,
  Col,
  Card,
  Button,
  Form,
  Typography,
  Select,
  Input,
  Table,
  Space,
  Drawer,
  InputNumber,
  Steps,
  Checkbox,
  Layout,
  Spin,
  Avatar,
  Radio,
  Alert,
  Menu,
  Tour,
  Result,
  Dropdown,
  Descriptions,
  Modal,
  message
} from "antd";
import moment from 'moment'
import { CheckOutlined, GoogleOutlined, ExclamationCircleOutlined } from "@ant-design/icons";
import { useParams, useNavigate , Link } from 'react-router-dom'
import { company, prefix } from "./index.const.jsx";

const { confirm } = Modal;
const { Step } = Steps;
const { Title, Text, Paragraph } = Typography;
const { Header, Content, Footer  } = Layout;

const Page = () => {

  const navigate = useNavigate();
  const id = '64ad859fffc480455db08c12';

  const [form_read] = Form.useForm();
  const [form_sign] = Form.useForm();
  const [form_confirm] = Form.useForm();

  const [loading, setLoading] = useState(false);
  const [stepForm, setStepForm] = useState(1);


  const [mounted, setMounted] = useState(false);
  const [authData, setAuthData] = useState(false);
  const [checked, setChecked] = useState(false);
  const [checkedConfirm, setCheckedConfirm] = useState(false);
  const [companyName, setCompanyName] = useState('');
  const [formDataSign, setFormDataSign] = useState({});

  const [formData, setFormData] = useState({name:'',drive:[]});



  const company_index = {
        "BA":"BA",
        "BC":"BCGH",
        "BO":"BO",
        "BM":"BM",
        "BV":"BV",
        "BI":"BI",
        "BW":"BWT",
        "BP":"BP",
        "BB":"BBT",
    }


  const fetchForm = (callback) => {
      fetch('/api/consent/'+id)
        .then(async response => {
          const data = await response.json();
          console.log('data=====>',data)
          if(!response.ok) {
            const error = (data && data.message) || response.statusText;
            return Promise.reject(error);
          }
          if(data.status == false) {
             setStepForm(4)
             callback()
          } else {
            setFormData(data.data)
            callback()
          }
        }).catch(error => {
          setStepForm(4)
          callback()
        })
  }

  useEffect(() => {
      setMounted(false)
      fetchForm(()=>{
        fetch('/process/auth/check')
          .then(async response => {
            const data = await response.json();
            console.log('data=====>',data)
            if(!response.ok) {
              const error = (data && data.message) || response.statusText;
              return Promise.reject(error);
            }
            if(data.status == false) {
               navigate("/?form=/consent/"+id);
            } else {
              var _company = company.filter(item=>item.key==company_index[data.user.emp_id.slice(0,2)])[0]
              console.log('_company===>',_company)
              
              setCompanyName(_company.label_en)
              setAuthData(data)
              setFormDataSign({
                sign_date:moment().format('YYYY-MM-DD'),
                company_name:_company.label.split(':')[1],
                company_key:_company.key,
                company:{},
                name_en:'',
                name_th:'',
                team:'',
                position:'',
                email:data.user.email,
                employee_id:data.user.emp_id
              })

              setMounted(true)
            }
          }).catch(error => {
            console.log('error=>call api',error)
          })
      })
   
  },[])

  const onChange = (e) => {
    console.log(`checked = ${e.target.checked}`);
    setChecked(e.target.checked)
  };

  const onChangeConfirm = (e) => {
    console.log(`checked = ${e.target.checked}`);
    setCheckedConfirm(e.target.checked)
  };

  const onFinishSign = (values) => {
    if(checked==true){
      values.company = formData.drive.filter(item=>item.key==formDataSign.company_key)[0]
      console.log('_company_data===>',values,formDataSign.company_key)
      setFormDataSign({...formDataSign,...values})
      console.log({...formDataSign,...values})

      message.success('Create Electronic signature complete')
      setStepForm(2)

    }
  }

  const RenderFormSign = () => {
    return (
    <>
      <Form form={form_sign} layout="vertical" onFinish={onFinishSign} initialValues={formDataSign}>
        <Row gutter={[24, 24]} className="mb-10">
          <Col span={24}>
              <Card className="form-law">
                  <Row gutter={[24, 32]}>
                    <Col span={24} align="right">
                      <Title level={4} underline align="center">
                        Food Credit Consent
                      </Title>
                      <Text>
                        Date: {formDataSign.sign_date}
                      </Text>
                    </Col>
                    <Col span={24}>
                      <Space direction="vertical" style={{ display: "flex" }}>
                        <Card title="DETAILS OF EMPLOYEE">
                          <Row gutter={[12, 12]}>
                            <Col xs={{ span: 24 }} lg={{ span: 24 }}>
                              <Form.Item name="company_name" label="Name of Company (Company):"  rules={[{required: true,message: ""}]}>
                                <Input className="w-100"  placeholder="Company name Co.,Ltd."/>
                              </Form.Item>
                            </Col>
                            <Col xs={{ span: 24 }} lg={{ span: 24 }}>
                              <Form.Item name="name_en"  label="Full Name of employee (EN):" rules={[{required: true,message: ""}]}>
                                <Input className="w-100" placeholder="Name Surname (EN)"/>
                              </Form.Item>
                            </Col>
                            <Col xs={{ span: 24 }} lg={{ span: 24 }}>
                              <Form.Item name="name_th"  label="Full Name of employee (TH):" rules={[{required: true,message: ""}]}>
                                <Input className="w-100" placeholder="Name Surname (TH)"/>
                              </Form.Item>
                            </Col>
                            <Col xs={{ span: 24 }} lg={{ span: 12 }}>
                              <Form.Item name="email" label="Email:" rules={[{required: true,message: ""}]}>
                                <Input className="w-100" />
                              </Form.Item>
                            </Col>
                                <Col xs={{ span: 24 }} lg={{ span: 12 }}>
                              <Form.Item name="employee_id" label="Employee ID:(Ex.BCGH001)" rules={[{required: true,message: ""}]}>
                                <Input className="w-100" />
                              </Form.Item>
                            </Col>
                            <Col xs={{ span: 24 }} lg={{ span: 12 }}>
                              <Form.Item name="team"  label="Team:" rules={[{required: true,message: ""}]}>
                                <Input className="w-100" placeholder="Team"/>
                              </Form.Item>
                            </Col>
                            <Col xs={{ span: 24 }} lg={{ span: 12 }}>
                              <Form.Item name="position" label="Position:" rules={[{required: true,message: ""}]}>
                                <Input className="w-100"  placeholder="Position"/>
                              </Form.Item>
                            </Col>
                          </Row>
                        </Card>
                      </Space>
                    </Col>
                  </Row>
              </Card>
          </Col>
          <Col span={24} align="left">
                <Checkbox onChange={onChange}>ข้าพเจ้าได้ทำการกรอกข้อมูลตามความเป็นจริง ข้างต้นทั้งหมดครบถ้วนดีแล้ว</Checkbox>
          </Col>
          <Col span={24} align="left">
      
              <Button type="primary" htmlType="submit" disabled={!checked}>
                Next
              </Button>
     
          </Col>
        </Row>
      </Form>

    </>
    )
  }



  const RenderFormConfirm = () => {
    return (
    <>
      <Form form={form_confirm} layout="vertical" onFinish={onFinishConfirm}>
        <Row gutter={[24, 24]} className="mb-10">
          <Col span={24}>
              <Card className="form-law">
                  <Row gutter={[24, 32]}>
                    <Col span={24} align="right">
                      <Title level={4} underline align="center">
                        Food Credit Consent
                      </Title>
                      <Text>
                        Date: {formDataSign.sign_date}
                      </Text>
                    </Col>
                    <Col span={24}>
                      <Space direction="vertical" style={{ display: "flex" }}>
                        <Title level={5} underline  align="center">หนังสือตกลงยินยอม</Title>
                        <Paragraph>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;ตามที่ {formDataSign.name_th} ตำแหน่ง {formDataSign.position} แผนก {formDataSign.team} รหัสพนักงาน {formDataSign.employee_id} (<Text strong>“พนักงาน”</Text>) พนักงานของ {formDataSign.company_name} (<Text strong>“บริษัท”</Text>) ได้รับทราบเป็นอย่างดีว่า ด้วยสภาพเศรษฐกิจที่อยู่ในช่วงขาลง ทำให้บริษัทมีความจำเป็นอย่างยิ่งที่จะต้องปรับลดค่าใช้จ่ายต่าง ๆ รวมถึงเครดิตค่าอาหารรายเดือน นั้น</Paragraph>
                        <Paragraph>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;พนักงานเข้าใจความจำเป็นของบริษัทข้างต้นเป็นอย่างดีแล้วและตกลงยินยอมปรับลดเครดิตค่าอาหาร      รายเดือน จำนวน 3,000.00 บาท (สามพันบาทถ้วน) ต่อเดือน โดยสมัครใจ โดยตกลงให้เหลือ จำนวน 1,200.00 บาท (หนึ่งพันสองร้อยบาทถ้วน) ต่อเดือน นับตั้งแต่วันที่ 1 สิงหาคม 2566 เป็นต้นไป และตกลงไม่เรียกร้องใด ๆ จากบริษัทแต่อย่างใด</Paragraph>
                        <Paragraph>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;เพื่อเป็นหลักฐานความตกลงยินยอมของพนักงานข้างต้นด้วยความสมัครใจ พนักงานจึงขอลงลายมือชื่อไว้เป็นหลักฐาน </Paragraph>
                      </Space>
                    </Col>
                    <Col span={10} align="start"></Col>
                    <Col span={8}>
                          <Space direction="vertical" align="center">
                            <Text>(____{formDataSign.name_en}____)</Text>
                            <Text>
                              (........{formDataSign.name_th}.........)
                            </Text>
                          </Space>
                    </Col>
                    <Col span={6} align="start">
                          <Space direction="vertical" align="start">
                            <Text>พนักงานผู้ให้ความยินยอม</Text>
                            <Text></Text>
                          </Space>
                    </Col>
                    <Col span={10} align="start"></Col>
                    <Col span={8}>
                          <Space direction="vertical" align="center" className="w-70">
                            <Text>(_______สุชาติ ภวสิริพร________)</Text>
                            <Text>(นายสุชาติ ภวสิริพร)</Text>
                            <Text>ประธานเจ้าหน้าที่กลุ่มด้านทรัพยากรบุคคล</Text>
                          </Space>
                    </Col>
                    <Col span={6} align="start">
                          <Space direction="vertical" align="start" className="w-30">
                            <Text>พยาน</Text>
                            <Text></Text>
                            <Text></Text>
                            <Text></Text>
                          </Space>
                    </Col>
                  </Row>
              </Card>
          </Col>
          <Col span={24} align="left">
                <Checkbox onChange={onChangeConfirm}>ข้าพเจ้าได้อ่าน และเข้าใจรายละเอียด รวมถึงได้ทำการกรอกข้อมูลตามความเป็นจริง ข้างต้นทั้งหมดครบถ้วนดีแล้ว</Checkbox>
          </Col>
          <Col span={24} align="left">
      
              <Button type="primary" onClick={onFinishConfirm} disabled={!checkedConfirm}>
                ยืนยันการลงลายมือชื่อผ่านระบบอิเล็กทรอนิกส์
              </Button>
     
          </Col>
        </Row>
      </Form>

    </>
    )
  }

  function onFinishConfirm() {

    confirm({
      icon: <ExclamationCircleOutlined />,
      title:"Confirm ?",
      content: 'ยืนยันการลงลายมือชื่อผ่านระบบอิเล็กทรอนิกส์?',
      onOk() {
        setLoading(true)
        var body = {
         method: 'post',
         headers: {'Content-Type':'application/json'},
         body: JSON.stringify(formDataSign)
        }
        console.log(body)
        fetch('/api/consent/'+id,body)
          .then(async response => {
            const data = await response.json();
            console.log('data=====>',data)
            if(!response.ok) {
              const error = (data && data.message) || response.statusText;
              return Promise.reject(error);
            }else{
              message.success('Complete')
              setStepForm(3)
              setLoading(false)
            }
          }).catch(error => {
            message.error('กรุณาลองใหม่อีกครั้ง')
            setLoading(false)
          })
      },
      onCancel() {
        console.log('Cancel');
      },
    });

  }

  function onBack() {
      setStepForm(2)
  }


  const RenderFormResult = () => {
    return (
       <Result
          status="success"
          title="ทำการส่งแบบฟอร์มสำเร็จ"
          extra={[
            <a href='/process/auth/logout/'><Button type="primary">
              Done and Logout
            </Button></a>
          ]}
        />
      )
  }


  const RenderFormResultEmpty = () => {
    return (
       <Result
          title="ไม่พบแบบแฟอร์ม"
          extra={[
            <a href='/process/auth/logout/'><Button type="primary">
              Done and Logout
            </Button></a>
          ]}
        />
      )
  }



  return mounted ? (
    <Spin spinning={loading} delay={500}>
      <Layout>
          <Header className="w-100" theme={'light'}>
            <Space className="w-100 justify-space-between">

                <Space size="middle"  align="center">
                  <a className="App-brand"><img src={'/logo.png'} style={{ height: 39 }}/></a>
                </Space>
                <Space size="middle"  align="center">
                      <Dropdown overlay={(
                <Menu>
                    <Menu.Item key="1">
                        <a href='/process/auth/logout/'>Logout</a>
                    </Menu.Item>
                </Menu>
            )} trigger={["click"]}>
                          <a className="ant-dropdown-link" onClick={(e) => e.preventDefault()}>
                              <Space>
                                  <Avatar src={authData.user.avatar} className="mr-2" />
                                  <div className="mr-2">
                                      <h5 className="mb-0">
                                          {authData.user.name}
                                      </h5>
                                  </div>
                              </Space>
                          </a>
                      </Dropdown>
                </Space>
            </Space>
          </Header>

          <Layout className="wrap">
              {(mounted && stepForm ==1) && <RenderFormSign />}
              {(mounted && stepForm ==2) && <RenderFormConfirm />}
              {(mounted && stepForm ==3) && <RenderFormResult />}
              {(mounted && stepForm ==4) && <RenderFormResultEmpty />}
          </Layout>
      </Layout>
    </Spin>
  ) : (<></>);
};

export default Page;
